///<reference path="../../../../node_modules/@angular/http/src/headers.d.ts"/>
import {Component,Input, Output, OnInit, EventEmitter} from '@angular/core';
import {FileService} from "../../file.service";
import {Headers} from "@angular/http";
import {HttpHeaders} from "@angular/common/http";
import * as moment from 'moment';
declare const watermark: any;

export class FileHolder {
  public serverResponse: any;
  public pending: boolean = false;
  constructor(public src: string, public file: File) { }
}

@Component({
  selector: 'npp-upload',
  templateUrl: './upload.component.html',
  styleUrls: ['./upload.component.css']
})
export class UploadComponent implements OnInit {
  get headers(): HttpHeaders {
    return this._headers;
  }

  set headers(value: HttpHeaders) {
    this._headers = value;
  }
  @Input() max: number = 100;
  @Input()  _headers: HttpHeaders;
  @Input() preview: boolean = true;
  @Input() multiple:boolean = true;
  @Input() idInput:string='multi';
  @Input() addTimeWaterMark: boolean = false;
  @Input() timeWaterMark: string;
  @Output()
  isPending: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output()
  onFileUploadFinish: EventEmitter<FileHolder> = new EventEmitter<FileHolder>();
  @Output()
  onRemove: EventEmitter<FileHolder> = new EventEmitter<FileHolder>();

  private files: FileHolder[] = [];
  private fileCounter: number = 0;
  private pendingFilesCounter: number = 0;

  isFileOver:boolean = false;

  @Input()
  buttonCaption: string = "Select Images";
  @Input()
  dropBoxMessage: string = "Drop your images here!";

  constructor(private imageService: FileService) { }

  ngOnInit() {
    if(this.multiple == true){
      $('#multi').attr('multiple','multiple');
    }else{
      $('#multi').removeAttr('multiple');
    }

  }

  getImageUrl(url){
    if(url) {
      var filename = url.substr(url.indexOf(".")+1 );
      if(filename != 'pdf'){
        return '/download?f=' + url;
      }else{
        return '../../../assets/pdf-image.png';
      }

    }else{
      return '../../../assets/user-n.png';
    }
  }


  fileChange(files) {
    let remainingSlots = this.countRemainingSlots();
    let filesToUploadNum = files.length > remainingSlots ? remainingSlots : files.length;
    if (filesToUploadNum != 0) {
      this.isPending.emit(true);
    }
    this.fileCounter += filesToUploadNum;

    if(this.addTimeWaterMark && !this.multiple){
      watermark(files)
        .blob(watermark.text.atPos((boat, metrics, context)=>{return 30},
                                    (boat, metrics, context)=>{return 30},
                                    this.timeWaterMark ? this.timeWaterMark : moment().format('DD/MM/YYYY HH:mm:ss'),
                                    '25px Arial-BoldItalicMT', '#e0ac04', 1))
        .then(img => {
          let fileimg = new File([img], files[0].name, {
            type: files[0].type,
            lastModified: Date.now()
          });

          this.uploadFiles([fileimg], filesToUploadNum);
          $("#multi").val('');
        });
    }else {
      this.uploadFiles(files, filesToUploadNum);
      $("#multi").val('');
    }
  }
  private uploadFiles(files, filesToUploadNum) {
    for (let i = 0; i < filesToUploadNum; i++) {
      let file = files[i];
      //let img = document.createElement('img');
      //img.src = window.URL.createObjectURL(file);
      let reader = new FileReader();
      reader.addEventListener('load', (event: any) => {
        let fileHolder: FileHolder = new FileHolder(event.target.result, file);
        fileHolder.serverResponse = `good boy: ${i}`;
        var filename = fileHolder.src.substr(fileHolder.src.indexOf("/")+1,3 );
        if(filename == 'pdf')
          fileHolder.src = '../../../assets/pdf-image.png';
        this.uploadSingleFile(fileHolder);
        this.files.push(fileHolder);
      }, false);
      reader.readAsDataURL(file);
    }
  }

  private uploadSingleFile(fileHolder: FileHolder) {
    if (fileHolder) {
      this.pendingFilesCounter++;
      fileHolder.pending = true;

      this.imageService.upload([fileHolder.file], this._headers).subscribe(nppRes => {
        fileHolder.serverResponse = nppRes;
        this.onFileUploadFinish.emit(fileHolder);
        fileHolder.pending = false;
        if (--this.pendingFilesCounter == 0) {
          this.isPending.emit(false);
        }
      });
    } else {
      this.onFileUploadFinish.emit(fileHolder);
    }
  }
  private deleteFile(file: FileHolder): void {
    let index = this.files.indexOf(file);
    this.files.splice(index, 1);
    this.fileCounter--;
    this.onRemove.emit(file);
    this.onFileUploadFinish.emit(file);
  }
  fileOver(isOver) {
    this.isFileOver = isOver;
  }
  private countRemainingSlots() {
    return this.max - this.fileCounter;
  }
}
//
