import {Component, OnInit, EventEmitter} from '@angular/core';
import {Input, Output} from "@angular/core";
import {isUndefined} from "util";
import {isNullOrUndefined} from "util";

@Component({
  selector: 'npp-col-header',
  templateUrl: './col-header.component.html',
  styleUrls: ['./col-header.component.css']
})
export class ColHeaderComponent implements OnInit {
  @Input() showIconSort = false;
  @Input() sortBy: string;
  @Output() onSort = new EventEmitter();
  _sort : any = {}
  isSortedByMeAsc: boolean = false;
  isSortedByMeDesc: boolean = false;
  constructor() { }

  ngOnInit() {
    this.isSortedByMeDesc = this.showIconSort;
  }
  sort() {
    if(isNullOrUndefined(this.sortBy) || this.sortBy=='')
      return ;

    if(this._sort[this.sortBy]==null || isUndefined(this._sort[this.sortBy])){
      this.isSortedByMeAsc = true;
      this.isSortedByMeDesc = false;
      this._sort[this.sortBy] = 1
    }
    else{
      this.isSortedByMeAsc = !this.isSortedByMeAsc;
      this.isSortedByMeDesc = !this.isSortedByMeDesc;
      this._sort[this.sortBy] = -this._sort[this.sortBy];
    }
//
    this.onSort.emit(this._sort);
  }
}
