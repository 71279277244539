import { Injectable, Inject, Injector } from '@angular/core';
import { Route, Router } from "@angular/router";
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/observable/of';
import 'rxjs/add/operator/do';
import 'rxjs/add/operator/delay';
import { ApiService } from "./api.service";
import { NppRequest } from "./npp-request";
import { NppResponse } from "./npp-response";

@Injectable()
export class AuthService {

  constructor(private apiService: ApiService, private injector: Injector) { }
  currentUser: any;
  moduleRouter: any;
  isLoggedIn: boolean = false;

  login(username, password): Observable<NppResponse> {
    var nppReq = new NppRequest('security', 'login', '', { email: username, password: password });
    return this.apiService.postRequest(nppReq);
  }

  requestCurrentUser(): Observable<NppResponse> {
    var nppReq = new NppRequest('security', 'currentuser', '', {});
    return this.apiService.postRequest(nppReq);
  }

  logout() {
    sessionStorage.clear();
    localStorage.clear();
    var nppReq = new NppRequest('security', 'logout', '', {});
    this.apiService.postRequest(nppReq).subscribe((res: NppResponse) => {
      if (res && res.result == 0) {
        this.isLoggedIn = false;
        this.currentUser = null;
      }
      this.injector.get(Router).navigate(['/login']);
    });
  }

  private mapComponent(r, componentMapping, isRoot): Route[] {
    for (var index in r) {
      var value = r[index];
      if (value.component) {
        value.component = componentMapping[value.component];
      }
      if (value.children) {
        this.mapComponent(value.children, componentMapping, false);
      }
    }
    if (isRoot) {
      return r;
    }
  }

  getRoute(module, componentMapping) {
    if (!this.moduleRouter)
      return [];

    var route: Route[] = [this.moduleRouter[module]];
    return this.mapComponent(route, componentMapping, true);
  }

  setRoute(route) {
    this.moduleRouter = route;
  }

  checkAction(module, page, actionName) {
    var listActions = this.currentUser.pages[module]['children'];
    var check = false;
    for (var key in listActions) {
      if (listActions[key].path == page) {
        var actions = listActions[key].actions;
        for (var key2 in actions) {
          if (actions[key2] == actionName) {
            check = true;
            break
          }
        }
        break
      }
    }
    return check;
  }
}