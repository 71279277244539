import {Component, OnInit, ContentChild, Input, AfterViewInit, Output, EventEmitter, ViewChild} from '@angular/core';
import {DataTable} from "../../npp-common/grid/datatable.class";
import {isUndefined} from "util";
import {NppBroadcast} from "../broadcast/broadcast";

@Component({
  selector: 'npp-grid',
  templateUrl: './grid.component.html',
  styleUrls: ['./grid.component.css']
})
export class GridComponent implements OnInit ,AfterViewInit{
  dataTable : DataTable<any>;
  _sort : any = {};
  @Output() pageChanged = new EventEmitter();
  @Output() sort = new EventEmitter();
  @Output() tableChanged = new EventEmitter();
  @Output() rowClick = new EventEmitter();
  @ContentChild('rowTemplate') rowTemplate;
  @ContentChild('headerTemplate') headerTemplate;
  @ViewChild('header') header ;
  @Input() tableHeight:number;
  @Input() checkBox:boolean = false;
  loading:boolean = true;
  @Output() selectedItemChanged = new EventEmitter();
  constructor(private nppBroadcast: NppBroadcast) {}
  @Input()
  get table(){
    return this.dataTable ;
  }
  set table(value){
    this.dataTable = value;
    this.tableChanged.emit(this.dataTable);
    this.loading = true;
  }
  ngOnInit() {
    this.nppBroadcast.get().subscribe(message => {
      if(message.from == 'search'){
        if(message.data == true){
          // this.table.items = [];
        }
        this.loading = message.data;
      }
    });
  }
  changPage(page){
    this.table.currentPageIndex=page;
    this.pageChanged.emit(page)
  }
  ngAfterViewInit()
  {

   /* var ths = this.header.nativeElement.children;
    for(var i=0;i<ths.length;i++ ){
      ths[i].onclick=(ev)=>{
        var a = ev.target.attributes.getNamedItem('sort');
        if(a){
          console.log(a.value);
          if(this._sort[a.value]==null || isUndefined(this._sort[a.value] ))
            this._sort[a.value] = 1
          else
            this._sort[a.value] = (-this._sort[a.value])
          this.sort.emit(this._sort);
        }
      }
    }*/
    $(".view-top-scroll").scroll(function(){
      $(".wrap-table-list")
        .scrollLeft($(".view-top-scroll").scrollLeft());
    });
    $(".wrap-table-list").scroll(function(){
      $(".view-top-scroll")
        .scrollLeft($(".wrap-table-list").scrollLeft());
    });
  }
  checkAll(event){
    this.table.items.forEach(item=>{
      if(!item.disabled){
        item.checked=event.target.checked
      }

    });
    this.selectedItemChanged.emit();
  }
  check(event){
    event.stopPropagation();
    this.selectedItemChanged.emit();
  }
  onRowClick(item,i){
    if (window.getSelection().toString())
      return ;
    item.$index = i;
    this.rowClick.emit(item);
  }
  onDrap(e){
    e.stopPropagation();
  }
}
