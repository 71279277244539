import { PHONE_PROVIDER } from "./hsv/loanCU/defineQDE";
import * as moment from 'moment';

const validPhoneProvider = (numPhone: string): boolean => {
  const num_provider = numPhone.slice(0, 3);
  return PHONE_PROVIDER.allProvider.includes(num_provider);
};

const phoneFormatter = (phone: string): string => {
  if (!phone) return '';
  let numberPhone = phone.replace(/[^\d]/g, '');
  numberPhone = numberPhone.replace(/(\d{3})(\d{3})(\d)/, '$1 $2 $3');
  return numberPhone;
};

const abstract_cols = (col: number = 3, colsm?: number, colmd?: number, collg?: number, colxl?: number) => {
  return ` cols col-sm-${colsm || col} col-md-${colmd || col} col-lg-${collg || col} `;
}


const duplicatedInArrObj = (arrObj: any[], keyGroup: string , value: string) => {
  let isDup = false;
  arrObj.reduce((y, x) => {
    if (x[keyGroup] !== y[x[keyGroup]]) {
      // eslint-disable-next-line no-param-reassign
      y[x[keyGroup]] = x[keyGroup];
      return y;
    }
    if(x[keyGroup] === value) isDup = true;
    return y;
  }, {});
  return isDup;
};

const numberWithCommas = (x: number, s = ',') => {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, s);
}

const numberCurrency = (x, separator = ",", floatSeparator = ".", fixedPoint = 0): string => {
  let parts = x.toString().split(".");
  let first = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, separator);
  let second = (parts[1] && fixedPoint >= 0 ? floatSeparator + (fixedPoint >= 0 ? parseFloat(parts[1]).toFixed(fixedPoint) : parts[1]) : "")
  return (first + second);
}

const currencyToNumber = (x): number => {
  if (typeof x === 'number') return x;
  return parseInt(x.replace(/,/g, ''));
}

const FORMAT_TIME = (val , to: 'day' | 'hour' | 'minute' | 'second') => {
  if(to === 'day') return moment(val).format('DD-MM-YYYY');
  if(to === 'hour') return moment(val).format('DD-MM-YYYY hh');
  if(to === 'minute') return moment(val).format('DD-MM-YYYY hh:mm');
  if(to === 'second') return moment(val).format('DD-MM-YYYY hh:mm:ss');

}



export {
  validPhoneProvider,
  phoneFormatter,
  abstract_cols,
  duplicatedInArrObj,
  numberWithCommas,
  numberCurrency,
  currencyToNumber,
  FORMAT_TIME
}
