import {
  Component, OnInit, ViewChild, Input,Output, OnDestroy, AfterViewInit, ComponentFactoryResolver,
  EventEmitter, OnChanges, SimpleChanges
} from '@angular/core';
import {SearchCtrDirective} from "../search-ctr.directive";
import {ApiService} from "../../api.service";
import {NppResponse} from "../../npp-response";
import {NppRequest} from "../../npp-request";
import {SearchOption} from "./search-option";
import {NppBroadcast} from "../broadcast/broadcast";

@Component({
  selector: 'npp-common-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.css']
})
export class SearchComponent implements OnInit ,AfterViewInit, OnDestroy, OnChanges{
//
  @Input() controls: any[];
  @Input() extparams: any;
  @Input() options: SearchOption;
  @Input() api: any;
  @Input() reload:any ;
  params : any;
  @ViewChild(SearchCtrDirective) searchControls: SearchCtrDirective;
  @Output() onSearch: EventEmitter<NppResponse> = new EventEmitter<NppResponse>();
  @Output() onExport: EventEmitter<NppResponse> = new EventEmitter<NppResponse>();
  CtrlInstances : any[];
  viewExport  : boolean;
  viewSearch  : boolean;
  export:boolean;
  constructor(private _componentFactoryResolver: ComponentFactoryResolver,private apiService:ApiService, private nppBroadcast: NppBroadcast) {
    this.viewExport = true;
    this.viewSearch = true;
    this.CtrlInstances = [];
  }
  ngOnInit() {

  }

  ngAfterViewInit() {
    var _this = this;
    let viewContainerRef = _this.searchControls.viewContainerRef;
    viewContainerRef.clear();
    for(var c in _this.controls){
      let componentFactory = _this._componentFactoryResolver.resolveComponentFactory(_this.controls[c]);
      let componentRef = viewContainerRef.createComponent(componentFactory);
      _this.CtrlInstances.push(componentRef.instance) ;
    }
  }
  ngOnDestroy() {

  }
  btnSearch(page){
    this.nppBroadcast.send({from: 'search', data: true, branch: (['cocobay'].indexOf(this.api.module) > -1 ? this.api.module: 'finviet')});
    if(this.extparams && page>0)
      this.extparams.page = page;
    this.reload=null;
    var nppReq = new NppRequest(this.api.module,this.api.page,this.api.action,this.buildParams())
    this.apiService.postRequest(nppReq).subscribe(
      response =>{
        this.nppBroadcast.send({from: 'search', data: false});
        this.onSearch.emit(response);
      },

      err => {
        this.nppBroadcast.send({from: 'search', data: false});
        this.onSearch.emit(err)
      }
    )
  }
  btnExport(){
    //  this.onSearch.emit(new NppResponse(0,'',{a:'1'}));
    this.export = true;
    var nppReq = new NppRequest(this.api.module,this.api.page,this.api.action,this.buildExportParams())
    this.apiService.postRequest(nppReq).subscribe(
      response =>{
        this.onExport.emit(response);
        this.export = false;
        // var clickExport = true;
        // this.onExport.emit(clickExport)
      },
      err =>this.onExport.emit(err)
    )
  }

  private buildExportParams(){
    var param = this.buildParams();
    param.export = true;
    return param;
  }
  private buildParams(){
    if(this.extparams.exportView == false){
      this.viewExport = this.extparams.exportView;
    }
    if(this.extparams.searchView == false){
      this.viewSearch = this.extparams.searchView;
    }
    var ctrlIns = this.CtrlInstances;
    var params = this.extparams||{};
    params.export = false;
    if(ctrlIns && ctrlIns.length>0){
      for (var cI in ctrlIns){
        var fields = ctrlIns[cI].fields;
        for(var f in fields){
            params[f] = fields[f];
        }
      }
    }
    this.params= params;
    return params;
  }
  ngOnChanges(changes: SimpleChanges) {
    if(changes['reload']){
      this.btnSearch(null);
    }
  }
}
