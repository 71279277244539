import { Injectable } from '@angular/core';
import {Observable} from "rxjs";
import {NppResponse} from "./npp-response";
import {RequestOptions, Http, Headers, Response} from "@angular/http";
import {HttpClient, HttpEventType, HttpHeaders, HttpRequest, HttpResponse} from '@angular/common/http';

@Injectable()
export class FileService {
  uploadUrl:string='/upload';
  arrDownload:any = [];
  agentType:any = [];
  export:any = {};
  dateSearch:any = {};
  constructor (private http: HttpClient) {

  }
  upload(files: File[], headers?: HttpHeaders): Observable<NppResponse>{
    const file = files[0];
    const formData = new FormData();
    formData.append('fileUpload', file);
    const options = { headers };
    return this.http.post(this.uploadUrl, formData, options).map(this.extractData).catch(this.handleError)
  }
  upload2(files: File[], headers?: HttpHeaders): Observable<any>{
    const file = files[0];
    const formData = new FormData();
    formData.append('fileUpload', file);
    const options = { headers };

    return this.http.request(new HttpRequest('POST',
      this.uploadUrl,
      formData,
      {
        reportProgress: true
      }))
  }
  download(fileName){

  }
  private extractData(res: any) {
    let body = res;
    return body || {};
  }

  private handleError (error: HttpResponse<any> | any) {
    // In a real world app, you might use a remote logging infrastructure
    let errMsg: string;
    if (error instanceof HttpResponse) {
      const body: any = error || '';
      const err = body.error || JSON.stringify(body);
      errMsg = `${error.status} - ${error.statusText || ''} ${err}`;
    } else {
      errMsg = error.message ? error.message : error.toString();
    }
    console.error(errMsg);
    return Observable.throw(errMsg);
  }

}
