import { Component, OnInit, Input, ViewEncapsulation } from '@angular/core';
import { ApiService } from "../../../api.service";
import { NppRequest } from "../../../npp-request";
import { ActivatedRoute, Router } from "@angular/router";
import { AuthService } from "../../../auth.service";

@Component({
  selector: 'npp-user-profile',
  templateUrl: './my-profile.component.html',
  styleUrls: ['./my-profile.component.css'],
  encapsulation: ViewEncapsulation.None,
})
export class UserProfileComponent implements OnInit {
  api: any;
  itemUser: any;
  errors: any = {};
  isLoading: Boolean = false;

  constructor(private apiService: ApiService, private router: Router, private route: ActivatedRoute, private authService: AuthService) {
    this.api = {
      module: 'security',
      page: 'my-profile',
      action: ''
    }
  }

  ngOnInit() {
    this._checkIsAdmin(this.authService.currentUser);
    this.resetFields();
  }

  resetFields() {
    const { email, firstname, lastname } = this.authService.currentUser;
    this.itemUser = { email, firstname, lastname, password: '', rePassword: '' };
  }

  _checkIsAdmin(user) {
    if (!!user.admin) this.router.navigate(['/admin/user-detail'], { queryParams: { userid: user.id } });
  }

  async update(form) {
    const { itemUser } = this;
    const { firstname, lastname, password, rePassword } = itemUser;
    let errors = {};

    if (!firstname || !firstname.length) errors['firstname'] = true;
    if (!lastname || !lastname.length) errors['lastname'] = true;

    var regex = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*()_+}{:;'?/.,><~-]).{6,20}$/;
    if (password && !regex.test(password)) {
      errors['password'] = true;
    }

    if ((!!password && !!password.length) || (!!rePassword && !!rePassword.length)) {
      if (password !== rePassword) errors['rePassword'] = true;
    }

    if (!Object['keys'](errors).length) {
      this.isLoading = true;

      let params: any = {
        firstname,
        lastname,
        email: this.authService.currentUser.email,
      }

      if (!!password && !!password.length) params.password = this.itemUser.password;

      const nppReq = new NppRequest(this.api.module, this.api.page, this.api.action, params);
      const response = await this.apiService.postRequest(nppReq).toPromise();
      if (!!response && response.result === 0) this.authService.logout();
      else this.isLoading = false;
    }
    this.errors = errors;

  }
}
