import {Component, OnInit, Input, Output, EventEmitter, ViewEncapsulation} from '@angular/core';
import {isNullOrUndefined} from "util";
import {Select2OptionData, Select2TemplateFunction} from "ng2-select2";

@Component({
  selector: 'npp-pick-list',
  templateUrl: './pick-list.component.html',
  styleUrls: ['./pick-list.component.css'],
 // encapsulation: ViewEncapsulation.None
})
export class PickListComponent implements OnInit{
  _selectedItem : any
  @Output() selectedItemChange = new EventEmitter();
  @Input() items : any;
  @Input() idAttr : string;
  @Input() nameAttr : string;
  @Input() parent : any;
  @Input() parentAttr : string;
  @Input() label : string;
  @Input() options : any;
  @Input() templateWithDescription:boolean = false;
  @Input() classOptional: any;
  constructor() {
  }

  ngOnInit() {
    if(this.templateWithDescription){
      this.options = {
        templateResult: this.templateResultWithDesc
      }
    }
  }
  @Input()
  get selectedItem(){
    return this._selectedItem;
  }
  set selectedItem(value){
    if(''+this._selectedItem == ''+value)
      return;
    this._selectedItem = value;
    this.selectedItemChange.emit(this._selectedItem);
  }
  itemsFiltered(){
    var iFi = this.parent?this.items.filter(item=>item.parent==this.parent || item[this.parentAttr]==this.parent):this.items;
    for(var key in iFi)
   {
     let item = iFi[key];
     if(this.idAttr)
        item.id=item[this.idAttr]
     if(this.nameAttr)
       item.text=item[this.nameAttr]
     else
       item.text=item.name || item.text;
   };
    return iFi;

  }
  onChange(event){
    setTimeout(()=>{
      this.selectedItem = event.value;
    },10)
  }

  // function for selection template
  public templateResultWithDesc: Select2TemplateFunction = (state: Select2OptionData): JQuery | string => {
    return jQuery(`
        <div style="font-weight:bold">${state.text}</div>
        <div style="">${state.text}</div>
    `);
  }

  // function for selection template
  public templateSelection: Select2TemplateFunction = (state: Select2OptionData): JQuery | string => {
    if (!state.id) {
      return state.text;
    }

    return jQuery('<span><b>' + state.additional.winner + '.</b> ' + state.text + '</span>');
  }
}
