import { Directive, ElementRef, Renderer2, ViewChild, ContentChild, AfterContentInit } from '@angular/core';
import { NgModel } from '@angular/forms';

@Directive({
  selector: '[isNumber]'
})
export class NumberValidatorDirective implements AfterContentInit {
  element: ElementRef;
  renderer: Renderer2;
  @ContentChild(NgModel) inputNumber: NgModel;

  constructor(element: ElementRef, renderer: Renderer2) {
    this.element = element;
    this.renderer = renderer;
  }

  ngAfterContentInit() {
    this.inputNumber.valueChanges.subscribe(() => {
      if (
        this.inputNumber.invalid &&
        (this.inputNumber.dirty || this.inputNumber.touched) ||
        !Number.isNaN(parseFloat(this.inputNumber.value)) && !Number.isNaN(this.inputNumber.value - 0)) {
        this.addErrorClass();
      } else {
        this.removeErrorClass();
      }
    })
  }

  addErrorClass(): void {
    this.renderer.addClass(this.element.nativeElement, 'error');
  }

  removeErrorClass(): void {
    this.renderer.removeClass(this.element.nativeElement, 'error');
  }
}