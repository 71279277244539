import {Component} from '@angular/core';
import {ActivatedRoute} from "@angular/router";
import {ApiService} from './api.service';
import {NppRequest} from "./npp-request";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class NppBaseComponent {
  smodule: string;
  spage: string;
  constructor(private route: ActivatedRoute,
              private apiService: ApiService){
    this.smodule = this.route.snapshot['_urlSegment'].segments[0].path;
    this.spage = this.route.snapshot['_urlSegment'].segments[1].path;
  }

  callApi(actions, params={}){
    const req = new NppRequest(this.smodule, this.spage, actions, params);
    return this.apiService.postRequest(req);
  }
}
