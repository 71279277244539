import { abstractSendAction } from './../../services';
import { NppToasterService } from './../../../npp-common/toaster/toaster.service';
import { Component, ViewEncapsulation, Input, EventEmitter, Output, OnInit } from '@angular/core';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { ApiService } from 'app/api.service';
import { SELECT_OPTION, API_DEFINE } from 'app/loan-referral/layout/form-type';

@Component({
  selector: 'custom-radio',
  templateUrl: 'custom-radio.component.html',
  styleUrls: ['custom-radio.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class CustomRadio implements OnInit {
  @Input() aliasName: string;
  @Input() valueBind: any;

  @Input() labelName: string;
  @Input() showAlert: boolean = false;
  @Input() typeSelect: 'radio' = 'radio';
  @Input() placeholder: string = '';

  @Input() required: boolean = false;
  @Input() disabled: boolean = false;
  @Input() listOption: SELECT_OPTION[] = [];
  @Input() apiAjax: API_DEFINE = null;
  @Input() set bindOptions(listOptBind: SELECT_OPTION[]) { this.listOption = listOptBind };
  @Output() valueBindChange = new EventEmitter();
  @Output() onChange = new EventEmitter();
  constructor(public apiService: ApiService, private toaster: NppToasterService) { }

  ngOnInit() {
    if (this.apiAjax) this.fetchData()
  }

  async fetchData(apiAJAX?: API_DEFINE) {
    try {
      const res = await abstractSendAction(this.apiService, this.toaster, apiAJAX ? apiAJAX : this.apiAjax);
      if (!res) {
        this.toaster.failed(`Không thể lấy dữ liệu ${apiAJAX ? apiAJAX.action : this.apiAjax.action}`);
        return;
      }
      if (res.result !== 0) {
        this.toaster.failed(res.err_msg);
        return;
      }
      this.listOption = res.data;
      return;
    }
    catch (e) {
      this.toaster.failed(e);
    }

  }

  onModelChange() {
    this.valueBindChange.emit(this.valueBind);
  }
}