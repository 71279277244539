import {Component, Input, AfterViewInit, ViewChild, ComponentFactoryResolver, OnDestroy, ChangeDetectorRef} from '@angular/core';
import { DynamicDirective } from './dynamic-directive';

@Component({
  selector: 'dynamic-component',
  template: '<ng-template dynamic-host></ng-template>'
})
export class DynamicComponent implements AfterViewInit {
  @Input() components: any[];
  @Input() data: any = {}
  @Input() tpl: number = 0;
  @Input() currentUser;
  currentAddIndex: number = -1;
  @ViewChild(DynamicDirective) adHost: DynamicDirective;
  subscription: any;
  interval: any;

  constructor(private componentFactoryResolver: ComponentFactoryResolver, private changeDetectorRef: ChangeDetectorRef) { }

  ngAfterViewInit() {
    this.loadComponent();
  }

  loadComponent() {
    let adItem = this.components[this.tpl];

    let componentFactory = this.componentFactoryResolver.resolveComponentFactory(adItem.component);

    let viewContainerRef = this.adHost.viewContainerRef;
    viewContainerRef.clear();

    let componentRef = viewContainerRef.createComponent(componentFactory);
    (<any>componentRef.instance).data = this.data;
    (<any>componentRef.instance).currentUser = this.currentUser;
    this.changeDetectorRef.detectChanges();
  }
}
