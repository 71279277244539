/**
 * Created by phung.nguyen on 5/15/2017.
 */
import {Component} from '@angular/core';
import {Router} from "@angular/router";

@Component({
  selector: 'app-layout-full',
  templateUrl: './full-layout.component.html',
  styles: [
    `.no-sidebar.wrapper {margin-top:0 !important;} .no-sidebar .main-content {padding-left:0 !important;}`
  ]
})
export class FullLayoutComponent {
  currentPath = '';
  pathNoSidebarLayout = ['/opp/upload-dialog?CKEditor=editor1&CKEditorFuncNum=1&langCode=en'];
  constructor(private router: Router){
    this.currentPath = this.router.url;
  }
}
