import {Component, OnInit, ViewEncapsulation, Input} from '@angular/core';
import {NgbActiveModal} from "@ng-bootstrap/ng-bootstrap";
import {FileHolder} from "./upload.component";

@Component({
  selector: 'ckeditor-upload-image',
  templateUrl: 'ckeditor-upload-image.html',
  styleUrls: ['ckeditor-upload-image.css']
})
export class CkeditorUploadImageComponent implements OnInit {
  constructor(public activeModal: NgbActiveModal) {
  }

  ngOnInit() {
  }

  onFileUploadFinish(fileHolder: FileHolder) {
    this.activeModal.close(fileHolder.serverResponse.data.url);
  }
}
