import {Component, OnInit, Output, EventEmitter, Input, AfterViewInit} from '@angular/core';
import {Script} from '../../script.service';
declare var moment:any;
@Component({
  selector: 'npp-from-to-rank-picker',
  templateUrl: './from-to-rank-picker.component.html',
  styleUrls: ['./from-to-rank-picker.component.css']
})
export class FromToRankPickerComponent implements OnInit, AfterViewInit {
  @Input() initData:any = {
    fromdate: new Date().setHours(0,0,0),
    todate: new Date().setHours(23,59,59),
  };
  @Input() options : any;
  @Output() fieldsChange = new EventEmitter();
  constructor(private script:Script) {
  }
  ngOnInit(){
  }
  ngAfterViewInit(){
    this.script.load('moment').then(data => {
      this.script.load('daterangepicker').then(data => {
        var start = moment(this.initData.fromdate).format('MM/D/YYYY');
        var end = moment(this.initData.todate).format('MM/D/YYYY');

        let cb = (start, end) => {
          if(typeof start != 'string'){
            this.initData.fromdate = new Date(start).setHours(0,0,0)
            this.initData.todate = new Date(end).setHours(23,59,59);
          }else{
            start = moment(this.initData.fromdate);
            end = moment(this.initData.todate);
          }
          this.fieldsChange.emit();
          $('.fdate-tdate').html('Từ <span style="font-weight: bold;color:#5A5A5A">' + start.format('D/MM/YYYY') + '</span> đến <span style="font-weight: bold;color:#5A5A5A">' + end.format('D/MM/YYYY') + '</span>');
        }

        (<any>$('.fdate-tdate')).daterangepicker({
          startDate: start,
          endDate: end,
          locale: {
            cancelLabel: 'Hủy',
            applyLabel: 'Áp dụng',
            customRangeLabel: 'Từ ngày đến ngày'
          },
          ranges: {
            'Ngày hôm nay': [moment(), moment()],
            'Ngày hôm qua': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
            '7 ngày gần nhất': [moment().subtract(6, 'days'), moment()],
            '30 ngày gần nhất': [moment().subtract(29, 'days'), moment()],
            'Tháng này': [moment().startOf('month'), moment().endOf('month')],
            'Tháng trước': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')]
          }
        }, cb);

        cb(start, end);
      });
    });
  }
}

