import {Component,OnInit,ElementRef,AfterContentInit} from "@angular/core";
import {AuthService} from "./auth.service";
import {Router} from "@angular/router";

@Component({
  moduleId: module.id,
  templateUrl: 'home.component.html',
  styleUrls: ['./home.component.css'],
})

export class HomeComponent implements OnInit,AfterContentInit  {
  listModule:any;
  constructor(private authService: AuthService,private router:Router,private elementRef: ElementRef){}
  getListPages(){
    this.listModule = this.authService.currentUser.pages.root;
    var _this = this;
    this.listModule.forEach(function (value,key) {
      var page = _this.authService.currentUser.pages[value.path];
      value.hidden = true;
      if(page!=null){
        for(let key in page.children){
          if(page.children[key].actions.length>0){
            value.hidden = false;
          }
        }
      }
    })
    this.listModule.sort((a,b)=>{
      if(a.name>b.name)
        return 1;
      if(a.name < b.name)
        return -1;
      return 0;
    })
  }
  ngAfterContentInit() {
    var el = this.elementRef.nativeElement.parentNode;
    el.style.paddingLeft = 0;
  }
  ngOnInit() {
    this.getListPages();
  }
  goPage(pageOpen){
    console.log("pageOpen", pageOpen)
    var _this = this;
    if(['cocobay'].indexOf(pageOpen) > -1){ // cho những trang nào muốn vào trang chủ ['cocobay', 'duongquabay']
      return _this.router.navigate([pageOpen]);
    }
    if(['agent-banking'].indexOf(pageOpen) > -1){ // cho những trang nào muốn vào trang chủ ['cocobay', 'duongquabay']
      return _this.router.navigate([pageOpen]);
    }
    var obj = this.authService.currentUser.pages[pageOpen]['children'];
    console.log('obj', obj)
    for (var i = 0; i < obj.length; i++)
    {
      console.log('obj[i][\'show_menu\']', obj[i]['show_menu'])
      if(obj[i]['show_menu']){
        _this.router.navigate([pageOpen+'/'+ obj[i].path]);
        return;
      }
    }
  }

  logout() {
    console.log('logout');
    this.authService.logout();
  }


}
