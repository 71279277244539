import {Component, OnInit, Input, OnDestroy} from '@angular/core';

@Component({
  selector: 'npp-ewallet-viewimage',
  templateUrl: './ewallet-viewimage.component.html',
  styleUrls: ['./ewallet-viewimage.component.css']
})
export class EwalletViewimageComponent implements OnInit, OnDestroy {
  @Input() imgs;
  @Input() index;
  config = {
    initialSlide: '',
    pagination: '.swiper-pagination',
    paginationClickable: true,
    nextButton: '.swiper-button-next',
    prevButton: '.swiper-button-prev',
    spaceBetween: 0
  };

  //config[initialSlide] = this.index;
  constructor() {

  }

  ngOnInit() {
    this.config.initialSlide = this.index;
    document.addEventListener("keydown", function(event) {
      let key = null
      if (event.which === 37 || event.which === 40) { // arrow left
        key = document.getElementsByClassName('swiper-button-prev')[0]
      } else if (event.which === 39 || event.which === 38) { // arrow right
        key = document.getElementsByClassName('swiper-button-next')[0]
      }
      if (key) key.click()
    })
  }

  ngOnDestroy() {
    document.removeEventListener('keydown', () =>{})
  }

}
