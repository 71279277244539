import {AfterViewInit, Component, ElementRef, OnInit} from '@angular/core';
import {NavigationEnd, Router} from "@angular/router";
import {AuthService} from "../../auth.service";
import {FileService} from "../../file.service";
import {Utils} from "../utils";
import {ApiService} from '../../api.service';
import {NppRequest} from '../../npp-request';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {AgentBalanceComponent} from './modal/agent-balance.component';

@Component({
  selector: 'app-navigation',
  templateUrl: './navigation.component.html',
  styleUrls: ['./navigation.component.css'],
  host: {
    '(document:click)': 'onClick($event)',
  },
})
export class NavigationComponent implements OnInit, AfterViewInit {
  export: any = {};
  activeDownload: any = false;
  loading: any = true;
  currentUser: any;
  dateSearch: any = {};
  showProfile:boolean = false;
  public sideBarOpen: boolean = false;
  static tmpData: any = {
    downloadCounter: 0,
    file: [],
    export:'',
    type:''
  };
  arrFileName: any;

  constructor(private router: Router,
              private authService: AuthService,
              private fileService: FileService,
              private modalService: NgbModal) {
    var _this = this;
    $(document).mouseup(function (e:any) {
      var container = $(".wrap-2-box-show");
      // if the target of the click isn't the container nor a descendant of the container
      if (!container.is(e.target) && container.has(e.target).length === 0) {
        container.hide();
        _this.activeDownload = false;
      }
    });
  }

  ngOnInit() {
    var __this = this;
    this.currentUser = this.authService.currentUser;
    this.router.events.subscribe((val)=> {
      if (val instanceof NavigationEnd) {
        this.sideBarOpen = false;
      }
    })

    __this.export = this.fileService.export;

  }

  get staticDownloadCounter() {
    return NavigationComponent.tmpData.downloadCounter;
  }

  set staticDownloadCounter(number) {
    NavigationComponent.tmpData.downloadCounter = number;
  }
  get staticExport() {
    return NavigationComponent.tmpData.export;
  }

  static getNameFile(name,number){
    var str = name;
    if(number==1){
      var filename = str.substr(str.indexOf("_")+1 );
      return filename;
    }else{
      var filename = str.substr(0, str.indexOf("_"));
      return filename;
    }

  }
  static getDownloadArr(file,date,expt,arr,type){
    if(!arr){
      arr = [];
    }
    var fileName = this.getNameFile(file, 1);
    var fileDate = this.getNameFile(file, 2);

    arr.push({name: fileName, date: fileDate, fileName: file});
    localStorage.setItem('arrDownload', JSON.stringify(arr));

    NavigationComponent.tmpData.dateSearch = date;
    NavigationComponent.tmpData.downloadCounter++;
    NavigationComponent.tmpData.export = expt;
    NavigationComponent.tmpData.type = type;

    setTimeout(function(){
      NavigationComponent.tmpData.export = false;
    },1000)
  }

  static getDownloadArr2(file, date, expt, arr, type, fileName) {
    if(!arr){
      arr = [];
    }
    var fileDate = this.getNameFile(file, 2);
    arr.push({name: fileName, date: fileDate, fileName: file});
    localStorage.setItem('arrDownload', JSON.stringify(arr));

    NavigationComponent.tmpData.dateSearch = date;
    NavigationComponent.tmpData.downloadCounter++;
    NavigationComponent.tmpData.export = expt;
    NavigationComponent.tmpData.type = type;

    setTimeout(function(){
      NavigationComponent.tmpData.export = false;
    },1000)
  }

  activeBoardDownload() {
    this.arrFileName = JSON.parse(localStorage.getItem('arrDownload')) || [];
    this.arrFileName.sort((a, b)=> {
      return a.date < b.date;
    })

    this.staticDownloadCounter = 0;
    var _this = this;
    setTimeout(function () {
      _this.loading = false;
    }, 2000)
    if (_this.activeDownload == false) {
      _this.activeDownload = true;
    } else {
      _this.activeDownload = false;
    }
  }

  downloadExcel(file) {
    if (file) {
      return '/download?f=' + file;
    }
  }

  logout() {
    this.fileService.arrDownload = [];
    this.authService.logout();
  }

  goUserDetail() {
    //this.router.navigate(['/admin/user-detail'],{ queryParams: { userid: this.currentUser.id } });

    const { currentUser } = this.authService;
    if(!currentUser.admin) this.router.navigate(['/my-profile']);
    var listRoute = !!currentUser.pages.admin ? currentUser.pages.admin.children : null;
    if (listRoute) {
      for (var iteam of listRoute) {
        if (iteam.component == 'UserComponent' || iteam.path == 'user') {
          var listAction = iteam.actions;
          if (listAction) {
            for (var iteamAction of listAction) {
              if (iteamAction == 'update') {
                window.open('/admin/user-detail?userid=' + this.currentUser.id);
              }
            }
          }
        }
      }
    }
  }

  showHideNavigation() {
    $(".wrapper").toggleClass("active");
  }

  public onHamburgerClick() {
    this.sideBarOpen = !this.sideBarOpen;
  }

  clickDownload(value, $event, index) {
    $event.stopPropagation();
    var _this = this;
    _this.activeDownload = false;
    var nameExcelDownload = '';
    if (NavigationComponent.tmpData.dateSearch != null) {
      var from = Utils.formatDate(NavigationComponent.tmpData.dateSearch.from);
      var to = Utils.formatDate(NavigationComponent.tmpData.dateSearch.to);
      if (NavigationComponent.tmpData.dateSearch.customDate) {
        nameExcelDownload = NavigationComponent.tmpData.type + '_' + NavigationComponent.tmpData.dateSearch.customDate + '.csv';
      } else {
        nameExcelDownload = NavigationComponent.tmpData.type + '_' + from + '_' + to + '.csv';
      }
    } else {
      nameExcelDownload = value;
    }
    $('.download').attr("href", "/download?f=" + value).attr("download", nameExcelDownload);
  }

  deleteFileExcel(file, $event) {
    $event.stopPropagation();
    if (file != 1) {
      for (var i = 0; i < this.arrFileName.length; i++) {
        if (this.arrFileName[i].fileName == file) {
          this.arrFileName.splice(i, 1);
          break;
        }
      }
      localStorage.setItem('arrDownload', JSON.stringify(this.arrFileName));
    } else {
      this.arrFileName.length = 0;
      localStorage.setItem('arrDownload', JSON.stringify(this.arrFileName));
    }

  }

  blurFunction() {
    this.activeDownload = false;
  }

  onClick(event) {
    if(typeof event.srcElement.className == 'string' && event.srcElement.className.split(' ').indexOf('click-show-profile') > -1) {
      this.showProfile = !this.showProfile;
    }else{
      this.showProfile = false;
    }
    // if (!this._eref.nativeElement.contains(event.target)) {
    //   alert('abc');
    // }
  }

  ngAfterViewInit(){

  }

  queryBalance() {
    const modalRef = this.modalService.open(AgentBalanceComponent);
  }

}
