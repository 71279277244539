import { CustomInputOption } from './custom-input.d';
import { Component, ViewEncapsulation, Input, EventEmitter, Output, OnInit } from '@angular/core';
import { numberCurrency } from 'app/loan-referral/utils';
import { TAG_NAME, TYPE_INPUT } from 'app/loan-referral/layout/form-type';

@Component({
  selector: 'custom-input',
  templateUrl: 'custom-input.component.html',
  styleUrls: ['custom-input.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class CustomInput implements OnInit {
  @Input() typeInput: TYPE_INPUT = 'text';
  @Input() aliasName: string;
  @Input() valueBind: any;
  @Input() labelName: string;
  @Input() showAlert: boolean = false;
  @Input() placeholder: string = '';
  @Input() inputClass: string = '';
  @Input() readonly: boolean = false;
  @Input() required: boolean = false;
  @Input() disabled: boolean = false;
  @Input() min: number = 0;
  @Input() max: number;
  @Input() minl: number;
  @Input() maxl: number;
  @Input() rules: {
    min?: () => Boolean,
    max?: () => Boolean,
    required?: () => Boolean,
    format?: () => Boolean,
  };
  @Input() dataByList: CustomInputOption = null;
  @Output() valueBindChange = new EventEmitter();
  @Output() onChange = new EventEmitter();

  tagInput: 'input' | 'textarea' = 'input';

  ngOnInit() {
    if (!this.dataByList) {
      switch (this.typeInput) {
        case 'textarea':
          this.tagInput = 'textarea';
          break;
        default:
          this.tagInput = 'input';
          break;
      }
    } else {
      switch (this.dataByList.typeInput) {
        case 'textarea':
          this.tagInput = 'textarea';
          break;
        default:
          this.tagInput = 'input';
          break;
      }
    }
  }

  onModelChange() {
    if (this.typeInput === 'currency' || (this.dataByList && this.dataByList.typeInput === 'currency')) {
      this.valueBind = this.valueBind.replace(/,/g, '');
      this.valueBind = numberCurrency(this.valueBind);
      this.valueBindChange.emit(this.valueBind);
    }
    else {
      this.valueBindChange.emit(this.valueBind);
    }
  }
}
