import {Component,OnInit,AfterContentInit} from "@angular/core";

@Component({
  templateUrl: 'page-not-found.component.html',
  styleUrls: ['./page-not-found.component.css'],

})

export class PageNotFoundComponent implements OnInit,AfterContentInit  {
  constructor(){}
  ngAfterContentInit() {
  }
  ngOnInit() {
  }

}
