import { Routes, RouterModule } from '@angular/router';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule } from '@angular/forms';
import { NgModule } from '@angular/core';

import { AuthGuard } from './auth.guard';
import { AuthService } from './auth.service';
import { HomeComponent } from './home.component';
import { LoginComponent } from './login.component';
import { FullLayoutComponent } from './layouts/full-layout.component';
import { CocobayLayoutComponent } from './layouts/cocobay-layout.component';
import { NoSideBarLayoutComponent } from './layouts/no-sidebar-layout.component';
import { UserProfileComponent } from './admin/user/my-profile/my-profile.component';
import { AgentBankingLayoutComponent } from "./agent-banking/common/layout/layout.component";

const routes: Routes = [
  { path: '', component: NoSideBarLayoutComponent, children: [{ path: '', component: HomeComponent, canActivate: [AuthGuard] }] },
  { path: 'login', component: LoginComponent },
  {
    path: '', component: CocobayLayoutComponent, canActivate: [AuthGuard], children: [
      { path: 'cocobay', loadChildren: './cocobay/cocobay.module#CocobayModule' }
    ]
  },
  {
    path: '', component: AgentBankingLayoutComponent, canActivate: [AuthGuard], children: [
      { path: 'agent-banking', loadChildren: './agent-banking/agent-banking.module#AgentBankingModule' }
    ]
  },
  {
    path: '', component: FullLayoutComponent, canActivate: [AuthGuard], children: [
      { path: 'my-profile', component: UserProfileComponent },
      { path: 'opp', loadChildren: './opp/opp.module#OppModule' },
      { path: 'nhnn', loadChildren: './nhnn/nhnn.module#NhnnModule' },
      { path: 'admin', loadChildren: './admin/admin.module#AdminModule' },
      { path: 'report', loadChildren: './report/report.module#ReportModule' },
      { path: 'finance', loadChildren: './finance/finance.module#FinanceModule' },
      { path: 'ewallet', loadChildren: './ewallet/ewallet.module#EwalletModule' },
      { path: 'risk-management', loadChildren: './risk-management/risk-management.module#RiskManagementModule' },
      { path: 'insurance', loadChildren: './insurance/insurance.module#InsuranceModule' },
      { path: 'transfer-eco', loadChildren: './transfer-eco/transfer-eco.module#TransferEcoModule' },
      { path: 'agentnetwork', loadChildren: './agent-network/agent-network.module#AgentNetworkModule' },
      { path: 'loan-referral', loadChildren: './loan-referral/loan-referral.module#LoanReferralModule' },
    ]
  },
  { path: '**', redirectTo: '', pathMatch: 'full' }
];

@NgModule({
  imports: [
    FormsModule,
    BrowserModule,
    RouterModule.forRoot(routes),
  ],
  exports: [RouterModule],
  providers: [
    AuthGuard,
    AuthService
  ]
})
export class AppRoutingModule { }