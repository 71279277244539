export const in_loanpurpose_Opts = [
  {
    name: 'Thiết bị/ Nội thất /Đồ điện tử',
    val: 'A'
  },
  {
    name: 'Phí y tế',
    val: 'M'
  },
  {
    name: 'Sửa sang/ Thanh toán nợ',
    val: 'H'
  },
  {
    name: 'Kì nghỉ/ Phí du lịch',
    val: 'V'
  },
  {
    name: 'Đa mục đích',
    val: 'P'
  },
];

export const in_laa_app_ins_applicable_Opts = [
  {
    name: 'Áp dụng',
    val: 'Y'
  },
  // {
  //   name: 'Không áp dụng',
  //   val: 'N'
  // },
];

export const in_priority_c_Opts = [
  {
    name: 'TK Ngân hàng',
    val: 'Bank Statement'
  },
  {
    name: 'GPĐK kinh doanh',
    val: 'Business License'
  },
  {
    name: 'Không GPĐK kinh doanh',
    val: 'No Business License'
  },
  {
    name: 'Tiền mặt',
    val: 'Pay Slip'
  },
  {
    name: 'Không',
    val: 'None'
  },
];

export const in_title_Opts = [
  {
    name: 'MR.',
    val: 'MR.'
  },
  {
    name: 'MRS.',
    val: 'MRS.'
  },
  {
    name: 'MS.',
    val: 'MS.'
  }
];

export const in_constid_Opts = [
  {
    name: 'Lương',
    val: '5'
  },
  {
    name: 'Tự doanh',
    val: '8'
  }
];

export const address_in_addresstype_Opts = [
  {
    name: 'Địa chỉ thường trú',
    val: 'PERMNENT'
  },
  {
    name: 'Địa chỉ hiện tại',
    val: 'CURRES'
  }
];

export const in_propertystatus_Opts = [
  {
    name: 'Sở hữu',
    val: 'O'
  },
  {
    name: 'Thuê',
    val: 'R'
  },
  {
    name: 'Nhà người thân',
    val: 'F'
  }
];

export const in_mailingaddress_Opts = [
  {
    name: 'Đối với addresstype là CURRES',
    val: 'Y'
  },
  {
    name: 'Đối với addresstype là PERMNENT',
    val: 'N'
  }
];

export const in_referalgroup_Opts = [
  {
    name: 'THIRD PARTY',
    val: '3'
  },
  {
    name: 'NORMAL',
    val: '4'
  }
];

export const in_addresstype_Opts = [
  {
    name: 'Trụ sở chính',
    val: 'HEADOFF'
  },
  {
    name: 'Chi nhánh',
    val: 'BCHOFF'
  }
];

export const in_refereerelation_Opts = [
  {
    name: 'Người quen', // Relative
    val: 'R'
  },
  {
    name: 'Đồng Người nộp đơn', // CO Applicant
    val: 'CA'
  },
  {
    name: 'Vợ/Chồng', // Wife/Husband
    val: 'WH'
  },
  {
    name: 'Bạn bè', // Friend
    val: 'F'
  },
  {
    name: 'Đồng nghiệp', // Colleague
    val: 'C'
  },
  {
    name: 'Bố/mẹ', // Parents
    val: 'PR'
  },
  {
    name: 'Con ruột', // Childrens
    val: 'CD'
  },
  {
    name: 'Anh/chị em ruột', // Sibling
    val: 'SI'
  },
  {
    name: 'Họ hàng', //Relatives
    val:'R'
  },
  {
    name: 'Đối tác', // Partner
    val: 'PN'
  },
  {
    name: 'Hàng xóm', // Neighbor
    val: 'NE'
  },
  {
    name: 'Người khác', // Other
    val: 'OT'
  },
];

export const in_accountbank_Opts = [
  {
    name: 'Có',
    val: 'Y'
  },
  {
    name: 'Không',
    val: 'N'
  }
];

export const in_sex_Opts = [
  {
    name: 'Nam',
    val: 'M'
  },
  {
    name: 'Nữ',
    val: 'F'
  },
]

export const in_paymentchannel_Opts = [
  {
    name : "Tự động",
    val : "T"
  },
  {
    name : "Bình Thường",
    val : "C"
  },
]

export const PHONE_PROVIDER = {
  viettel: ['086', '096', '097', '098', '032', '033', '034', '035', '036', '037', '038', '039'],
  vinaphone: ['088', '091', '094', '083', '084', '085', '081', '082'],
  mobiphone: ['089', '090', '093', '070', '079', '077', '076', '078'],
  vinamobile: ['092', '056', '058'],
  gmobile: ['099', '059'],
  allProvider: [
    '086',
    '096',
    '097',
    '098',
    '032',
    '033',
    '034',
    '035',
    '036',
    '037',
    '038',
    '039',
    '088',
    '091',
    '094',
    '083',
    '084',
    '085',
    '081',
    '082',
    '089',
    '090',
    '093',
    '070',
    '079',
    '077',
    '076',
    '078',
    '092',
    '056',
    '058',
    '099',
    '059',
  ],
};
