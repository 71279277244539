/**
 * Created by ty.pham on 07/06/2018.
 */
import {Component, ViewEncapsulation} from '@angular/core';
import {Router} from "@angular/router";

@Component({
  selector: 'app-agent-banking-full',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.css']
})
export class AgentBankingLayoutComponent {
  currentPath = '';

  constructor(private router: Router) {
    this.currentPath = this.router.url;
  }
}
