import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'phone'
})
export class PhonePipe implements PipeTransform {

  transform(telephone: any, args?: any): any {
      if (!telephone || telephone === undefined || telephone.length === 0) {
        return null;
      }
      var value = telephone.toString().trim().replace(/^\+/, '');

      if (value.match(/[^0-9]/)) {
        return telephone;
      }
      var country, city, number;
      switch (value.length) {
        case 10: // +1PPP####### -> C (PPP) ###-####
          country = 1;
          city = value.slice(0, 3);
          number = value.slice(3);
          break;
        case 11: // +CPPP####### -> CCC (PP) ###-####
          country = value[0];
          city = value.slice(1, 4);
          number = value.slice(4);
          break;
        default:
          country = 1;
          city = value.slice(0, 3);
          number = value.slice(3);
          break;
      }
      if (country == 1) {
        country = "";
      }
      number = number.slice(0, 3) + ' ' + number.slice(3);
      return (country + " " + city + " " + number).trim();
  }
}
