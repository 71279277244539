/**
 * Created by phung.nguyen on 5/15/2017.
 */
import {Component} from '@angular/core';

@Component({
  selector: 'app-layout-no-sidebar',
  templateUrl: './no-sidebar-layout.component.html'
})
export class NoSideBarLayoutComponent {

}
