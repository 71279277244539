import {Component} from '@angular/core';
import {NppBroadcast} from "../broadcast/broadcast";


@Component({
  moduleId: module.id,
  selector: 'processing',
  templateUrl: './processing.component.html'
})

export class ProcessingComponent {
  message:any = {processing:false};
  constructor(private nppBroadcast: NppBroadcast) {
  }

  ngOnInit() {
    this.nppBroadcast.get().subscribe(message => {
      this.message.branch = (message.branch? message.branch: 'finviet');
      if(message.from == 'search'){
        this.message.processing = message.data;
      }
    });
  }
}
