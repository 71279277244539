import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';

@Injectable()
export class NppToasterService {
  constructor(private toastsManager: ToastrService) {

  }

  success(message, time = 3000) {
    this.toastsManager.success(message, '', {
      timeOut: time
    });
  }

  failed(message, time = 3000, enableHTML = true) {
    this.toastsManager.error(message, '', {
      enableHtml: enableHTML,
      timeOut: time
    });
  }

  warning(message, title = '', time = 3000) {
    this.toastsManager.warning(message, title, {
      timeOut: time
    })
  }

  failedWithoutClose(message, time = 3000, enableHTML = true) {
    this.toastsManager.error(message, '', {
      enableHtml: enableHTML,
      timeOut: time
    });
  }
}
