import {Component, OnInit, Inject} from '@angular/core';
import {Router} from "@angular/router";
import {StartupService} from "./startup.service";
import {AuthGuard} from './auth.guard';
import {AuthService} from './auth.service';
import {ApiService} from './api.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {
  constructor(private router: Router, private startup: StartupService, private auth: AuthService, private guard: AuthGuard, private api: ApiService){
    this.guard.setAuthService(this.auth);
    this.guard.setStartupService(this.startup);
    this.api.setAuthService(this.auth);
  }

  ngOnInit() {
    if (!this.startup.startupData) {
      this.router.navigate(['error'], { replaceUrl: true });
    }
  }
}
