import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SearchComponent } from './search/search.component';
import { SearchCtrDirective } from './search-ctr.directive';
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { PaginationComponent } from "./grid/pagination.component";
import { GridComponent } from "./grid/grid.component";
import { PickListComponent } from './pick-list/pick-list.component';
import { Select2Module } from "ng2-select2";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { FromToPickerComponent } from './from-to-picker/from-to-picker.component';
import { UploadComponent } from './upload/upload.component';
import { FileDropDirective } from './upload/file-drop.directive';
import { NavigationComponent } from "./navigation/navigation.component";
import { SidebarComponent } from "./navigation/sidebar/sidebar.component";
import { RouterModule } from "@angular/router";
import { ColHeaderComponent } from './grid/col-header.component';
import { PhonePipe } from './phone.pipe';
import { TreeComponent } from './tree/tree.component';
import { PopupComponent } from "./popup/popup.component";
import { UploadImagesComponent } from './popup/upload-images.component';
import { ViewimageComponent } from './viewimage/viewimage.component';
import { InputComponent } from './input/input.component';
import { SwiperModule } from 'angular2-useful-swiper';
import { ImportComponent } from "./import/import.component";
import { FileImportComponent } from "./import/upload/file-import.component";
import { CurrencyInput, CurrencyMaskDirective } from "./input/currency-input.component";
import { DynamicDirective } from "./dynamic-component/dynamic-directive";
import { DynamicComponent } from "./dynamic-component/dynamic.component";
import { CkeditorUploadImageComponent } from "./upload/ckeditor-upload-image.component";
import { OrderByPipe } from "./orderby.pipe";
import { ProcessingComponent } from "./processing/processing.component";
import { FvUploadComponent } from './fv-upload/fv-upload.component';
import { CocobayNavigationComponent } from './navigation/cocobay-navigation.component';
import { CocobaySidebarComponent } from './navigation/sidebar/cocobay-sidebar.component';
import { FromToRankPickerComponent } from './from-to-rank-picker/from-to-rank-picker.component';
import { DatePickerComponent } from './date-picker/date-picker.component';
import { TextMaskModule } from 'angular2-text-mask';
import { AgentBalanceComponent } from './navigation/modal/agent-balance.component';
import { AgentBankingNavigationComponent } from "../agent-banking/common/navigation/navigation.component";
import { AgentBankingSidebarComponent } from "../agent-banking/common/sidebar/sidebar.component";
import { DataTableComponent } from '../agent-banking/common/components/datatable/datatable.component';
import { EwalletViewimageComponent } from './ewallet-viewimage/ewallet-viewimage.component'
import { SanitizeHtmlPipe } from './sanitizer.pipe';
import { SanitizeUrlPipe } from './sanitizeUrl.pipe';
import { CounterDirective } from './directive/countdown.directive';
import { CustomInput } from 'app/loan-referral/components/input/custom-input.component';
import { OnlyNumber } from 'app/loan-referral/components/directive/numberOnly.directive';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { CustomRadio } from 'app/loan-referral/components/radio/custom-radio.component';
import { DataTable2Component } from 'app/loan-referral/components/datatable/datatable2.component';
import { SpinnerComponent } from './spinner/index.component';
import { CustomSelect } from 'app/loan-referral/components/select/custom-select.component';
import { CustomSelect2 } from 'app/loan-referral/components/select2/index.component';
import { NumberValidatorDirective } from 'app/loan-referral/components/directive/validation/number.directive';
import { RequiredValidatorDirective } from 'app/loan-referral/components/directive/validation/require.directive';
import { NumberDirective } from './number-only';
import { DateDirective } from './date.directive';
import { NgbDateParserFormatter } from "@ng-bootstrap/ng-bootstrap";
import { MomentDateFormatter } from './momentdate';
import { CurrencyInputInline, CurrencyMaskDirectiveInline } from './input/currency-input-inline.component';
@NgModule({
  imports: [
  FormsModule,
    CommonModule,
    Select2Module,
    NgbModule,
    RouterModule,
    SwiperModule,
    ReactiveFormsModule,
    TextMaskModule,
    NgMultiSelectDropDownModule.forRoot()
  ],
  exports: [
    NumberDirective,
    NgbModule,
    SearchComponent,
    GridComponent,
    PickListComponent,
    FromToPickerComponent,
    UploadComponent,
    NavigationComponent,
    SidebarComponent,
    ColHeaderComponent,
    PhonePipe,
    TreeComponent,
    InputComponent,
    ImportComponent,
    FileImportComponent,
    CurrencyInput,
    CurrencyMaskDirective,
    CurrencyInputInline,
    CurrencyMaskDirectiveInline,
    ReactiveFormsModule,
    DynamicComponent,
    DynamicDirective,
    CkeditorUploadImageComponent,
    OrderByPipe,
    ProcessingComponent,
    FvUploadComponent,
    CocobayNavigationComponent,
    CocobaySidebarComponent,
    AgentBankingNavigationComponent,
    AgentBankingSidebarComponent,
    DataTableComponent,
    FromToRankPickerComponent,
    DatePickerComponent,
    TextMaskModule,
    AgentBalanceComponent,
    SanitizeHtmlPipe,
    SanitizeUrlPipe,
    FileDropDirective,
    CounterDirective,
    CustomSelect,
    CustomSelect2,
    CustomInput,
    CustomRadio,
    OnlyNumber,
    NumberValidatorDirective,
    RequiredValidatorDirective,
    DataTable2Component,
    SpinnerComponent,
  ],
  declarations: [
    NumberDirective,
    DateDirective,
    SearchComponent,
    SearchCtrDirective,
    PaginationComponent,
    GridComponent,
    PickListComponent,
    FromToPickerComponent,
    UploadComponent,
    FileDropDirective,
    NavigationComponent,
    SidebarComponent,
    ColHeaderComponent,
    PhonePipe,
    TreeComponent,
    PopupComponent,
    UploadImagesComponent,
    ViewimageComponent,
    InputComponent,
    ImportComponent,
    FileImportComponent,
    CurrencyInput,
    CurrencyMaskDirective,
    CurrencyInputInline,
    CurrencyMaskDirectiveInline,
    DynamicComponent,
    DynamicDirective,
    CkeditorUploadImageComponent,
    OrderByPipe,
    ProcessingComponent,
    FvUploadComponent,
    CocobayNavigationComponent,
    CocobaySidebarComponent,
    AgentBankingNavigationComponent,
    AgentBankingSidebarComponent,
    DataTableComponent,
    FromToRankPickerComponent,
    DatePickerComponent,
    AgentBalanceComponent,
    EwalletViewimageComponent,
    SanitizeHtmlPipe,
    SanitizeUrlPipe,
    CounterDirective,
    CustomSelect,
    CustomSelect2,
    CustomInput,
    CustomRadio,
    OnlyNumber,
    NumberValidatorDirective,
    RequiredValidatorDirective,
    DataTable2Component,
    SpinnerComponent,
  ],

  entryComponents: [
    PopupComponent,
    UploadComponent,
    UploadImagesComponent,
    ViewimageComponent,
    FileImportComponent,
    CkeditorUploadImageComponent,
    ProcessingComponent,
    CocobayNavigationComponent,
    CocobaySidebarComponent,
    AgentBankingNavigationComponent,
    AgentBankingSidebarComponent,
    DataTableComponent,
    FromToRankPickerComponent,
    DatePickerComponent,
    AgentBalanceComponent,
    EwalletViewimageComponent
  ],
  providers: [
    {provide: NgbDateParserFormatter, useClass: MomentDateFormatter}
   ]
})
export class NppCommonModule { }

