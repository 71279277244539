import { Injectable, Injector } from '@angular/core';

import 'rxjs/add/operator/map';
import 'rxjs/add/operator/toPromise';
import 'rxjs/add/operator/catch';
import { Router, Routes, NavigationStart } from "@angular/router";
import { AuthService } from "./auth.service";
import { AuthGuard } from "./auth.guard";
import { FullLayoutComponent } from "./layouts/full-layout.component";
import { CocobayLayoutComponent } from './layouts/cocobay-layout.component';
import { UserProfileComponent } from './admin/user/my-profile/my-profile.component';
import { AgentBankingLayoutComponent } from "./agent-banking/common/layout/layout.component";
import { LoanReferralComponent } from './loan-referral/loan-referral.component';

@Injectable()
export class StartupService {

  private _startupData: any;
  private _startupUrl: string;
  private router: Router;
  private authService: AuthService;

  constructor(private injector: Injector) {
  }

  // This is the method you want to call at bootstrap
  // Important: It should return a Promise
  load(): Promise<any> {

    this._startupData = null;
    this.authService = this.injector.get(AuthService);

    return this.authService.requestCurrentUser()
      .toPromise()
      .then((res: any) => {
        this._startupData = res;
        this.router = this.injector.get(Router);
        if (res && res.result == 0) {
          this.authService.isLoggedIn = true;
          this.authService.currentUser = res.data.user;
          this.authService.setRoute(res.data.user.pages);

          var startRoute: Routes = this.router.config.slice(0, 2);
          var authRoute: Routes = res.data.user.pages.root.filter(item => {
            return item.path != 'cocobay';
          });
          var cocobayRoute: Routes = res.data.user.pages.root.filter(item => {
            return item.path == 'cocobay';
          });
          if (cocobayRoute) {
            startRoute.push(
              { path: '', component: CocobayLayoutComponent, canActivate: [AuthGuard], children: cocobayRoute }
            );
          }
          const agentBankingRoute: Routes = res.data.user.pages.root.filter(item => {
            return item.path == 'agent-banking';
          });
          if (agentBankingRoute) {
            startRoute.push(
              { path: '', component: AgentBankingLayoutComponent, canActivate: [AuthGuard], children: agentBankingRoute }
            );
          }

          authRoute.push({ path: 'my-profile', component: UserProfileComponent })
          startRoute.push(
            { path: '', component: FullLayoutComponent, canActivate: [AuthGuard], children: authRoute },
          );
          startRoute.push({ path: '**', redirectTo: '' });
          console.log('c route: ', startRoute);

          this.router.resetConfig(startRoute);
        }
      })
      .catch((err: any) => Promise.resolve());
  }

  get startupData(): any {
    this.injector.get(Router).events.subscribe((event: any) => {
      if (event instanceof NavigationStart) {
        this._startupUrl = event.url;
      }
    });
    return this._startupData;
  }

  get startupUrl(): string {
    return this._startupUrl;
  }
}
