import {
  AgentTypeListCoCoBay,
  AgentTypes,
  AgentTypesCoCoBay,
  AgentTypesMbf2,
  Mbf2AgentTypeListForTree
} from "../npp-common/define";
import {AgentTypesMbf8} from "../npp-common/define";
import {AgentTypeList, LoanStatusList, Mbf8AgentTypeListForTree} from "../npp-common/define";

export class Utils {

  static obj2arr(obj: any) {
    var arr = [];
    for (var key in obj) {

      arr.push(obj[key]);
    }
    return arr;
  }

  static obj2arr4picklist(obj: any) {
    var arr = [];
    for (var key in obj) {
      var item = {id: null, name: ''};
      item.id = key;
      item.name = obj[key];
      arr.push(item);
    }
    return arr;
  }

  static getAgentLabel = function (agentType, branch) {
    if (branch == 'mbf8') {
      var mbf8AgentTypeList = AgentTypesMbf8;
      return mbf8AgentTypeList[agentType];
    } else if (branch == 'mbf2') {
      let mbf2AgentTypeList = AgentTypesMbf2;
      return mbf2AgentTypeList[agentType];
    } else {
      var agentTypes = AgentTypes;
      return agentTypes[agentType];
    }
  }

  static getCoCoBayAgentLabel = function (agentType, branch) {
    var agentTypes = AgentTypesCoCoBay;
    return agentTypes[agentType];
  }

  static getAgentTypeSummaryCoCoBay(summary, branch, listPhone, cb) {
    var arrList = [];
    var agentTypeList;


    agentTypeList = AgentTypeListCoCoBay;
    agentTypeList.forEach(function (agentType, index) {
      if (summary[agentType.code + '']) {
        arrList.push({
          code: 1,
          name: (summary[agentType.code + ''] + agentType.name) + ' | ',
          phone: listPhone[agentType.code]
        });
        if (index == agentTypeList.length - 1) {
          arrList[arrList.length - 1]['name'] = (summary[agentType.code + ''] + agentType.name);
          cb(arrList);
        }
      }
      else {
        if (index == agentTypeList.length - 1) {
          if (arrList.length > 0) {
            var nodeName = arrList[arrList.length - 1]['name'];
            if (nodeName.indexOf("|") > 0) {
              arrList[arrList.length - 1]['name'] = nodeName.substring(0, nodeName.length - 3);
            }
          }
          cb(arrList);
        }
      }
    })
  }

  static getAgentTypeSummary(summary, branch, listPhone, cb) {
    var arrList = [];
    var agentTypeList;
    var mbf8AgentTypeList = Mbf8AgentTypeListForTree;
    if (branch == 'mbf8') {
      agentTypeList = mbf8AgentTypeList;
    } else if (branch == 'mbf2') {
      agentTypeList = Mbf2AgentTypeListForTree;
    } else {
      agentTypeList = AgentTypeList;
    }
    agentTypeList.forEach(function (agentType, index) {
      if (summary[agentType.code + '']) {
        if (branch == 'mbf2') {
          arrList.push({
            code: agentType.code,
            name: (summary[agentType.code + ''] + agentType.name) + ' | ',
            phone: listPhone[agentType.code]
          });
        } else {
          arrList.push({
            code: 1,
            name: (summary[agentType.code + ''] + agentType.name) + ' | ',
            phone: listPhone[agentType.code]
          });
        }
        if (index == agentTypeList.length - 1) {
          arrList[arrList.length - 1]['name'] = (summary[agentType.code + ''] + agentType.name);
          cb(arrList);
        }
      }
      else {
        if (index == agentTypeList.length - 1) {
          if (arrList.length > 0) {
            var nodeName = arrList[arrList.length - 1]['name'];
            if (nodeName.indexOf("|") > 0) {
              arrList[arrList.length - 1]['name'] = nodeName.substring(0, nodeName.length - 3);
            }
          }
          cb(arrList);
        }
      }
    })
  }

  static formatAmountString = function (amount) {
    var amountFormat = Number((amount + '').replace(',', ''));
    var re = '(\\d)(?=(\\d{' + (3 || 3) + '})+' + (0 > 0 ? '\\.' : '$') + ')';
    return amountFormat.toFixed(Math.max(0, ~~0)).replace(new RegExp(re, 'g'), '$1,');
  }

//type = 'kios'

  static formatDate(dateNumber) {
    var monthNames = [
      "1", "2", "3",
      "4", "5", "6", "7",
      "8", "9", "10",
      "11", "12"
    ];
    var dateStr = new Date(dateNumber);
    var day = dateStr.getDate();
    var monthIndex = dateStr.getMonth();
    var year = dateStr.getFullYear();
    return day + monthNames[monthIndex] + year;
  }

  static getLoanStatusSummary(summary, type, cb) {
    var arrList = [];
    var statusCounter = {};
    var phoneSend = {};
    var phoneSendSum = '';
    var globalCounter = 0;
    var total = 0;
    var loanStatusList = LoanStatusList;
    summary.forEach(function (sum, index) {
      globalCounter++;
      loanStatusList.forEach(function (loanStatus, id) {
        if (!statusCounter[loanStatus[type].desc]) {
          statusCounter[loanStatus[type].desc] = 0;
          phoneSend[loanStatus[type].desc] = '';
        }
        if (loanStatus.code == sum._id) {
          statusCounter[loanStatus[type].desc] += sum.count;
          if (sum.loanid) {
            for (var iphone of sum.loanid) {
              phoneSend[loanStatus[type].desc] += iphone + ',';
              phoneSendSum += iphone + ',';
            }
          }
          globalCounter--;
        }
        if (index == summary.length - 1 && id == loanStatusList.length - 1) {
          var label = '';
          var arrStatusKey = Object.keys(statusCounter);
          arrStatusKey.forEach(function (key, ind) {

            total += statusCounter[key];
            label = statusCounter[key] + ' ' + key;
            if (key == 'Được duyệt' && statusCounter[key] > 0) {
              arrList.push({code: 8888, name: ' ' + label, phone: phoneSend[key]});
              arrList.push({code: 2, name: ' | '});
            } else {
              arrList.push({code: 2, name: ' ' + label + ' | ', phone: phoneSend[key]});
            }
            if (ind == arrStatusKey.length - 1) {
              if (total != 0) {
                arrList.unshift({code: 7777, name: '( '});
                arrList.unshift({code: 2, name: '- ' + total + ' Hồ sơ ', phone: phoneSendSum});
                var nameLast = arrList[arrList.length - 1].name;
                arrList[arrList.length - 1].name = nameLast.substring(0, nameLast.length - 2);
                if (arrList.length > 1) {
                  arrList.push({code: 7777, name: ')'});
                }
              } else {
                arrList = [];
                arrList.push({code: 2, name: '- 0 Hồ sơ '});
              }
              cb(arrList);
            }
          });
        }
      })
    })
  }

  static getStatusSummary(summary, type, statusList, cb) {
    var arrList = [];
    var statusCounter = {};
    var phoneSend = {};
    var phoneSendSum = '';
    var globalCounter = 0;
    var total = 0;
    summary.forEach(function (sum, index) {
      globalCounter++;
      statusList.forEach(function (status, id) {
        if (!statusCounter[status[type].desc]) {
          statusCounter[status[type].desc] = 0;
          phoneSend[status[type].desc] = '';
        }
        if (status.code == sum._id) {
          statusCounter[status[type].desc] += sum.count;
          if (sum.loanid) {
            for (var iphone of sum.loanid) {
              phoneSend[status[type].desc] += iphone + ',';
              phoneSendSum += iphone + ',';
            }
          }
          globalCounter--;
        }
        if (index == summary.length - 1 && id == statusList.length - 1) {
          var label = '';
          var arrStatusKey = Object.keys(statusCounter);
          arrStatusKey.forEach(function (key, ind) {

            total += statusCounter[key];
            label = statusCounter[key] + ' ' + key;
            if (key == 'Được duyệt' && statusCounter[key] > 0) {
              arrList.push({code: 7777, name: ' ' + label, phone: phoneSend[key]});
              arrList.push({code: 7777, name: ' | '});
            } else {
              arrList.push({code: 7777, name: ' ' + label + ' | ', phone: phoneSend[key]});
            }
            if (ind == arrStatusKey.length - 1) {
              if (total != 0) {
                arrList.unshift({code: 7777, name: '( '});
                arrList.unshift({code: 7777, name: '- ' + total + ' Hồ sơ ', phone: phoneSendSum});
                var nameLast = arrList[arrList.length - 1].name;
                arrList[arrList.length - 1].name = nameLast.substring(0, nameLast.length - 2);
                if (arrList.length > 1) {
                  arrList.push({code: 7777, name: ')'});
                }
              } else {
                arrList = [];
                arrList.push({code: 7777, name: '- 0 Hồ sơ '});
              }
              cb(arrList);
            }
          });
        }
      })
    })
  }

  static getLoanStatusSummaryRoot(summary, type, cb) {
    var arrList = [];
    var statusCounter = {};
    var phoneSend = {};
    var phoneSendSum = '';
    var globalCounter = 0;
    var total = 0;
    var loanStatusList = LoanStatusList;
    summary.forEach(function (sum, index) {
      globalCounter++;
      loanStatusList.forEach(function (loanStatus, id) {
        if (!statusCounter[loanStatus[type].desc]) {
          statusCounter[loanStatus[type].desc] = 0;
          phoneSend[loanStatus[type].desc] = '';
        }
        if (loanStatus.code == sum._id) {
          statusCounter[loanStatus[type].desc] += sum.count;
          if (sum.loanid) {
            for (var iphone of sum.loanid) {
              phoneSend[loanStatus[type].desc] += iphone + ',';
              phoneSendSum += iphone + ',';
            }
          }
          globalCounter--;
        }
        if (index == summary.length - 1 && id == loanStatusList.length - 1) {
          var label = '';
          var arrStatusKey = Object.keys(statusCounter);
          arrStatusKey.forEach(function (key, ind) {
            total += statusCounter[key];
            label = statusCounter[key] + ' ' + key;
            if (key == 'Được duyệt' && statusCounter[key] > 0) {
              arrList.push({code: 8888, name: ' ' + label, phone: phoneSend[key]});
              arrList.push({code: 2, name: ' | '});
            } else {
              arrList.push({code: 2, name: ' ' + label + ' | ', phone: phoneSend[key]});
            }
            if (ind == arrStatusKey.length - 1) {
              arrList.unshift({code: 7777, name: '( '});
              arrList.unshift({code: 2, name: '- ' + total + ' Hồ sơ ', phone: phoneSendSum});
              var nameLast = arrList[arrList.length - 1].name;
              arrList[arrList.length - 1].name = nameLast.substring(0, nameLast.length - 2);
              if (arrList.length > 1) {
                arrList.push({code: 7777, name: ')'});
              }
              cb(arrList);
            }
          });
        }
      })
    })
  }

  static checkVietkey = function (str) {
    var valid = true;
    str = str.toLowerCase();
    if (str.search(/à|á|ạ|ả|ã|â|ầ|ấ|ậ|ẩ|ẫ|ă|ằ|ắ|ặ|ẳ|ẵ|è|é|ẹ|ẻ|ẽ|ê|ề|ế|ệ|ể|ễ|ì|í|ị|ỉ|ĩ|ò|ó|ọ|ỏ|õ|ô|ồ|ố|ộ|ổ|ỗ|ơ|ờ|ớ|ợ|ở|ỡ|ù|ú|ụ|ủ|ũ|ư|ừ|ứ|ự|ử|ữ|ỳ|ý|ỵ|ỷ|ỹ|đ/) != -1) {
      return valid = false;
    }
  }
  static validatePhone = function (str, cb) {
    var resultString = '';
    var result = '';
    var arrayPhone = str.split(',');
    for (var i  in arrayPhone) {
      if (arrayPhone[i].length == 11 || arrayPhone[i].length == 10) {
        resultString += '' + arrayPhone[i] + ', ';
      } else {
        result += ' ' + (Number(i) + 1) + ', ';
      }
    }
    cb(result.substr(0, result.length - 1), resultString.substr(0, resultString.length - 1));
  }

  static wrapTextLength = function (text, position) {
    var t1 = text.substring(position);
    var t2 = t1.split('/')[0];
    return position + t2.length;
  }
  static timeDateFormatExcel = function (date) {
    return this.dateFormat(date, '%d/%m/%Y %H:%M:%s', false);
    // return this.dateFormat(date, '%Y-%m-%d %H:%M:%s', false);
  }
  static dateFormat = function (date, fstr, utc) {
    if (fstr == '' || fstr == null) {
      fstr = '%d/%m/%Y %H:%M:%s.%S';
    }
    utc = utc ? 'getUTC' : 'get';
    return fstr.replace(/%[YmdHMsS]/g, function (m) {
      switch (m) {
        case '%Y':
          return date[utc + 'FullYear'](); // no leading zeros required
        case '%m':
          m = 1 + date[utc + 'Month']();
          break;
        case '%d':
          m = date[utc + 'Date']();
          break;
        case '%H':
          m = date[utc + 'Hours']();
          break;
        case '%M':
          m = date[utc + 'Minutes']();
          break;
        case '%s':
          m = date[utc + 'Seconds']();
          break;

        case '%S':
          m = date[utc + 'Milliseconds']();
          return ('0' + m).slice(-3);
        default:
          return m.slice(1); // unknown code, remove %
      }
      // add leading zero if required
      return ('0' + m).slice(-2);
    });
  }
}
