import {Component, OnInit, Output, EventEmitter, Input, AfterViewInit, ElementRef} from '@angular/core';
import {Script} from '../../script.service';
declare var moment:any;
@Component({
  selector: 'npp-date-picker',
  templateUrl: './date-picker.component.html',
  styleUrls: ['./date-picker.component.css']
})
export class DatePickerComponent implements OnInit, AfterViewInit {
  @Input() initData:any = new Date().setHours(0,0,0);
  @Input() options : any;
  @Output() fieldsChange = new EventEmitter();
  constructor(private script:Script, private elRef: ElementRef) {
  }
  ngOnInit(){
  }
  ngAfterViewInit(){
    this.script.load('moment').then(data => {
      this.script.load('daterangepicker').then(data => {
        var start = moment(this.initData).format('DD/MM/YYYY');

        let datepicker = this.elRef.nativeElement.querySelector('.date-picker');
        (<any>($(datepicker))).daterangepicker({
          singleDatePicker: true,
          startDate: start,
          locale: {
            format: 'DD/MM/YYYY'
          }
        }, (start, end, label) =>{
          if(typeof start != 'string'){
            this.initData = new Date(start).setHours(0,0,0)
          }else{
            start = moment(this.initData);
          }
          this.fieldsChange.emit(this.initData);
          // $('.fdate-tdate').html('Từ <span style="font-weight: bold;color:#5A5A5A">' + start.format('D/MM/YYYY') + '</span> đến <span style="font-weight: bold;color:#5A5A5A">' + end.format('D/MM/YYYY') + '</span>');
        });
      });
    });
  }
}
