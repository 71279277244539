import { Component } from '@angular/core';



@Component({
  selector: 'spinner-loading',
  templateUrl: './index.component.html',
  styleUrls: ['./index.component.css']
})
export class SpinnerComponent {
}