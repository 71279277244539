import {Component, OnInit,ViewChild, ViewEncapsulation} from '@angular/core';
import {Router, ActivatedRoute, Routes} from "@angular/router";
import {AuthService} from "./auth.service";
import {AlertService} from "./alert.service";
import {sha1} from '../lib/sha1';
import {NppResponse} from "./npp-response";
import {FullLayoutComponent} from "./layouts/full-layout.component";
import {AuthGuard} from "./auth.guard";
import {CocobayLayoutComponent} from './layouts/cocobay-layout.component';
import {UserProfileComponent} from './admin/user/my-profile/my-profile.component';
import {AgentBankingLayoutComponent} from "./agent-banking/common/layout/layout.component";

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css'],
  // encapsulation: ViewEncapsulation.None,
})
export class LoginComponent implements OnInit {
  title = 'Hệ thống quản trị thông tin Finviet NPP';
  @ViewChild("form") form: any;
  user: any = {};
  loading = false;
  returnUrl: string;

  constructor(private route: ActivatedRoute,
              private router: Router,
              private authService: AuthService,
              private alertService: AlertService) {
  }

  ngOnInit() {
    this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/';
  }

  login() {
    if(this.form.valid){
      this.loading = true;

      this.authService.login(this.user.email, sha1(this.user.password))
        .subscribe(
          (response: NppResponse) => {
            this.loading = false;
            if (response && response.result == 0) {
              this.authService.isLoggedIn = true;
              this.authService.currentUser = response.data.user;
              this.authService.setRoute(response.data.user.pages);

              var startRoute: Routes = this.router.config.slice(0, 2);
              var authRoute: Routes = response.data.user.pages.root.filter(item=>{
                return item.path != 'cocobay' || item.path != 'agent-banking';
              });
              var cocobayRoute: Routes = response.data.user.pages.root.filter(item=>{
                return item.path == 'cocobay';
              });
              if(cocobayRoute){
                startRoute.push(
                  {path: '', component: CocobayLayoutComponent, canActivate: [AuthGuard],children: cocobayRoute}
                );
              }
              var agentBankingRoute: Routes = response.data.user.pages.root.filter(item=>{
                return item.path == 'agent-banking';
              });
              if(agentBankingRoute){
                startRoute.push(
                  {path: '', component: AgentBankingLayoutComponent, canActivate: [AuthGuard],children: agentBankingRoute}
                );
              }
              authRoute.push({path: 'my-profile', component: UserProfileComponent})
              startRoute.push(
                {path: '', component: FullLayoutComponent, canActivate: [AuthGuard],children: authRoute},
              );
              startRoute.push({path: '**', redirectTo: ''});
              console.log('c route: ', startRoute);

              this.router.resetConfig(startRoute);
              if(this.authService.currentUser.group == 'cocobay'){
                this.router.navigate(['/cocobay']);
              }else
              if (this.authService.currentUser.group == 'agent-banking') {
                this.router.navigate(['/cocobay']);
              } else {
                this.router.navigate([this.returnUrl]);
              }
            } else {

              this.alertService.error("Thông tin đăng nhập không chính xác");
            }
          },
          error => {
            this.loading = false;
            this.alertService.error(error);
          });
    }
    else{
      this.form.onSubmit();
    }

  }
}
