import { NppToasterService } from './../npp-common/toaster/toaster.service';
import { ApiService } from './../api.service';
import { NppRequest } from "app/npp-request";
import { API_DEFINE } from './layout/form-type';



export const abstractSendAction = async function (apiService: ApiService, toastr: NppToasterService, d: API_DEFINE): Promise<any> {
  const nppReq = new NppRequest(d.module, d.page, d.action, d.data);
  return new Promise((resolve) =>
    apiService.postRequest(nppReq).subscribe(
      (res: any) => {
        if (res.result == 0) {
          if (d.showToast) toastr.success(res.err_msg || d.txtSuccess);
          resolve(res);
        } else {
          if (d.showToast) toastr.failed(res.err_msg || d.txtError);
          resolve(res);
        }
      },
      (err: any) => {
        if (d.showToast) toastr.failed(err);
        resolve(null);
      }
    ));
}

export const nestTreeList = (items, id = null, link = 'parent') =>
  items
    .filter(item => item[link] === id)
    .map(item => ({ ...item, children: nestTreeList(items, item.id) }));