export default {
  convertStatus(status, toText = false) {
    switch (status) {
      case 'INIT':
        return toText ? 'Khởi tạo' : decodeURI('<span class="badge badge-primary"> Khởi tạo </span>')
      // case 'PENDING':
      //   return toText ? 'Chờ xác nhận' : decodeURI('<span class="badge badge-warning"> Chờ xác nhận </span>')
      case 'SUCCESS':
        return toText ? 'Thành công' : decodeURI('<span class="badge badge-success"> Thành công </span>')
      case 'FAILED':
        return toText ? 'Thất bại' : decodeURI('<span class="badge badge-danger"> Thất bại </span>')
      case 'DELETED':
        return toText ? 'Quá hạn xử lý' : decodeURI('<span class="badge badge-secondary"> Quá hạn xử lý </span>')
      case 'CONFIRMED':
        return toText ? 'Đã xác nhận' : decodeURI('<span class="badge badge-info"> Đã xác nhận </span>')
      case 'UPLOADED':
        return toText ? 'Đã tải lên' : decodeURI('<span class="badge badge-success"> Đã tải lên </span>')
      case 'PROCESSING':
        return toText ? 'Đang xử lý' : decodeURI('<span class="badge badge-warning"> Đang xử lý </span>')
      default:
        return ''
    }
  },
  numberCurrency(x, separator = ",", floatSeparator = ".", fixedPoint = 0) {
    if (!separator) separator = ",";
    else separator = separator[0] || ",";
    if (!floatSeparator) floatSeparator = ".";
    else floatSeparator = floatSeparator[0] || ".";
    fixedPoint = fixedPoint || 0;
    var parts = x.toString().split(".");
    return (
      parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, separator) +
      (parts[1] && fixedPoint >= 0 ?
        floatSeparator +
        (fixedPoint >= 0 ?
          parseFloat(parts[1]).toFixed(fixedPoint) :
          parts[1]) :
        "")
    );
  },
  convertBonusMethod(method) {
    switch (method) {
      case 'MANUAL':
        return "Thủ công"
      case 'AUTOMATIC':
        return "Tự động"
      default:
        return ''
    }
  },
}

export const STATUS_DEFINE = {
  'INIT': 'Khởi tạo',
  'SUCCESS': 'Thành công',
  'FAILED': 'Thất bại',
  'DELETED': 'Quá hạn xử lý',
  'CONFIRMED': 'Đã xác nhận',
}

export const STATUS_RECONCILIATION = {
  'INIT': 'Khởi tạo',
  'UPLOADED': 'Đã tải lên',
  'PROCESSING': 'Đang xử lý'
}

export const deepKeyInObj = (obj, key) => {
  switch (typeof key) {
    case 'string': {
      const strArr = [...key.split('.')];
      if (strArr.length === 0) return '';
      if (strArr.length === 1) return obj[strArr[0]];
      let tempObjStr = { ...obj };
      let iObj = strArr.length - 1;
      while (iObj >= 0) {
        const str = strArr.shift() || '';
        if (!tempObjStr[str]) return '';
        tempObjStr = tempObjStr[str];
        iObj -= 1;
      }
      return tempObjStr;
    }

    case 'object': {
      if (!Array.isArray(key)) return '';
      const strObj = [...key];
      if (strObj.length === 0) return '';
      if (strObj.length === 1) return obj[strObj[0]];
      let tempObjArr = { ...obj };
      let iArr = strObj.length - 1;
      while (iArr >= 0) {
        const str = strObj.shift() || '';
        if (!tempObjArr[str]) return '';
        tempObjArr = tempObjArr[str];
        iArr -= 1;
      }
      return tempObjArr;
    }
    default:
      return '';
  }
}