/**
 * Created by vu.nguyen on 5/6/2017.
 */
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'orderByPipe'
})
export class OrderByPipe implements PipeTransform {

  transform(array: Array<string>, args: string): Array<string> {

    if (!array || array === undefined || array.length === 0) return null;

    array.sort((a: any, b: any) => {
      if (a.date && typeof a.date === 'string')
        a.date = new Date(a.date).getTime();
      if (b.date && typeof b.date === 'string')
        b.date = new Date(b.date).getTime();

      if (a.date < b.date) {
        return 1;
      } else if (a.date > b.date) {
        return -1;
      } else {
        return 0;
      }
    });
    return array;
  }

}
