import {Component, OnInit, Output, EventEmitter, Input, OnChanges, SimpleChanges} from '@angular/core';
import {ISearchCtr} from "../i-search-ctr";

@Component({
  selector: 'npp-from-to-picker',
  templateUrl: './from-to-picker.component.html',
  styleUrls: ['./from-to-picker.component.css']
})
export class FromToPickerComponent implements OnInit,ISearchCtr {
  _fields : any = {};
  _data: any = {};
  @Input() initdata : any;
  @Input() options : any;
  @Input() fromLabel : string;
  @Input() toLabel : string;
  @Output() fieldsChange = new EventEmitter();
  constructor() {
    this.options={
      isFrom : true,
      isTo : true,
    }
    let from = new Date(new Date().toDateString());
    let to = from;
    this._fields = {
      from : {year:from.getFullYear(),month:from.getMonth()+1,day:from.getDate()},
      to : {year:to.getFullYear(),month:to.getMonth()+1,day:to.getDate()},
    };
  }
  hide() {

    let _f = this._fields;
    let c_e = this.fieldsChange;
    var f=this._data;
    if (_f.from != null) {
      f.fromdate = new Date(_f.from.year, _f.from.month - 1, _f.from.day).getTime();
    }else{
      delete f.fromdate
    }
    if (_f.to != null) {
      f.todate = new Date(_f.to.year, _f.to.month - 1, _f.to.day).setHours(23, 59, 59, 999);
    }else{
      delete f.todate
    }

    c_e.emit(f);
  }

  ngOnInit() {
    if(this.initdata){
      this.fields = this.initdata
    }
  }
  @Input()
  get fields(){

    this._data.fromdate = new Date(this._fields.from.year,this._fields.from.month-1,this._fields.from.day).getTime();
    this._data.todate = new Date(this._fields.to.year,this._fields.to.month-1,this._fields.to.day).setHours(23,59,59,999);
    return this._data;
  }
  set fields(value){
    let from = new Date(value.fromdate);
    let to = new Date(value.todate);
    this._fields = {
      from : {year:from.getFullYear(),month:from.getMonth()+1,day:from.getDate()},
      to : {year:to.getFullYear(),month:to.getMonth()+1,day:to.getDate()},
    };
    this._data = value;
    this.fieldsChange.emit(this._data);
  }
  onChange(d){
    //let f = this.fields;

    let _f = this._fields;
    let c_e = this.fieldsChange;
    var f = this._data;
    return function (e) {
      _f[d]= e;
        if(_f.from != null){
          f.fromdate = new Date(_f.from.year,_f.from.month-1,_f.from.day).getTime();
        }else{
          delete f.fromdate
        }
       if(_f.to !=null){
          f.todate = new Date(_f.to.year,_f.to.month-1,_f.to.day).setHours(23,59,59,999);
      }else{
        delete f.todate
       }


      setTimeout(()=>{
        c_e.emit(f);
      },10)


    }
  }
}

//
