import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewEncapsulation
} from '@angular/core';
import { FileHolder } from '../upload/upload.component';
import { FileService } from '../../file.service';
import { HttpEventType, HttpHeaders } from '@angular/common/http';
import { ViewimageComponent } from '../viewimage/viewimage.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
@Component({
  selector: 'fv-upload',
  templateUrl: 'fv-upload.component.html',
  styleUrls: ['fv-upload.component.css'],
  encapsulation: ViewEncapsulation.None
})

/*
* <upload-2 [(initData)]="arrImage" [uploadTip]="'Kéo và thả hình ảnh bên dưới để tải lên.Nhiều hình ảnh có thể được tải lên cùng một lúc.Tối đa\n' +
    '    8 ảnh, kích thước từ 330 * 330 đến 2000 * 2000 px.'" [multiple]="false" [fileTypes]="'.jpg,.png'" [options]="{progressColor:'blue'}"></upload-2>

<upload-2 [(initData)]="arrImage1" [uploadTip]="'Kéo và thả hình ảnh bên dưới để tải lên.Nhiều hình ảnh có thể được tải lên cùng một lúc.Tối đa\n' +
    '    8 ảnh, kích thước từ 330 * 330 đến 2000 * 2000 px.'" [multiple]="false" [fileTypes]="'.jpg,.png'" [options]="{progressColor:'blue'}"></upload-2>

<upload-2 [(initData)]="image" [uploadTip]="''" [multiple]="false" [fileTypes]="'.jpg,.png'" (initDataChange)="changeFile($event)" [options]="{progressColor:'blue'}"></upload-2>
*
*
* uploadType: 'single' || 'multiple' (mặc định nếu initData = undefined thì uploadType sẽ bằng 'single'
* nếu initData truyền vào là undefined nhưng muốn output ra là mảng thì input vào [uploadType]='multiple'
* */

export class FvUploadComponent implements OnInit, AfterViewInit {
  @Input() initData: any = []; // string for single upload or array for multiple upload
  @Input() cantDelete: boolean = false;
  @Input() label = '';
  @Input() uploadTip: any = '';
  @Input() multiple = false;
  @Input() uploadType = 'single'; // multiple
  @Input() validateFile: boolean = false;
  @Input() options: any = {
    colorHoverIcon: '#f00',
  }
  @Input() fileTypes = ".png,.jpg,.jpeg,.svg";
  @Output() initDataChange: EventEmitter<any> = new EventEmitter();
  isFileOver: boolean = false;
  idInput: string = Math.random().toString().substring(3);
  progressBar: any = {
    show: false,
    percent: 0
  }

  constructor(public imageService: FileService, public elRef: ElementRef, public modalService: NgbModal) {
  }

  ngOnInit() {
    if (!this.initData) {
      this.initData = (this.uploadType == 'single' ? '' : []);
    }
  }

  ngAfterViewInit() {
    $('')
    let sortContainer = this.elRef.nativeElement.querySelector('.sort-container');
    let _this = this;
    _this.emitFileChange();
    $(function () {
      (<any>$(sortContainer)).sortable({
        items: '.upload-item:not(.add-more)',
        update: (e, ui) => {
          _this.emitFileChange();
        }
      });
      (<any>$(sortContainer)).disableSelection();
    })

    // set multipe for input file
    if (this.multiple == true && typeof this.initData == 'object') {
      $('#' + this.idInput).attr('multiple', 'multiple');
    } else {
      $('#' + this.idInput).removeAttr('multiple');
    }

    // hover plus icon
    $(sortContainer).find('.upload-item.add-more').hover(() => {
      $(sortContainer).find('.plus-icon').css({ color: _this.options.colorHoverIcon ? _this.options.colorHoverIcon : '#f00' })
    }, () => {
      $(sortContainer).find('.plus-icon').css({ color: '#9E9E9E' })
    })
  }

  emitFileChange() {
    let sortContainer = this.elRef.nativeElement.querySelector('.sort-container');
    let result = [];
    $(sortContainer).find('.upload-item:not(.add-more)').each((idx, item) => {
      result.push($(item).attr('data-url'));
      if (idx == $(sortContainer).find('.upload-item:not(.add-more)').length - 1) {
        this.initData = result;
        this.initDataChange.emit(result);
      }
    });
  }

  remove(idx) {
    this.initData.splice(idx, 1);
    this.initDataChange.emit(this.initData);
  }

  fileOver(isOver) {
    this.isFileOver = isOver;
  }

  fileChange(files) {
    this.uploadFiles(files);
    $('#' + this.idInput).val('');
  }

  private uploadFiles(files) {
    for (let i = 0; i < files.length; i++) {
      let file = files[i];
      let img = document.createElement('img');
      img.src = window.URL.createObjectURL(file);
      let reader = new FileReader();
      reader.addEventListener('load', (event: any) => {
        let fileHolder: FileHolder = new FileHolder(event.target.result, file);
        fileHolder.serverResponse = `good boy: ${i}`;
        var filename = fileHolder.src.substr(fileHolder.src.indexOf('/') + 1, 3);
        if (filename == 'pdf')
          fileHolder.src = '../../../assets/pdf-image.png';
        this.uploadSingleFile(fileHolder);
      }, false);
      reader.readAsDataURL(file);
    }
  }

  private uploadSingleFile(fileHolder: FileHolder) {
    if (fileHolder) {
      console.log("validateFile ====",this.validateFile);
      if (this.validateFile) {
        const fileType = fileHolder.file.type.substr(fileHolder.file.type.indexOf('/') + 1);
        const validateFileType = this.validateBeforeUpdate(fileType);
        if (!validateFileType) {
          alert("File không hợp lệ")
          return;
        }
      }
      this.progressBar.show = true;
      this.imageService.upload2([fileHolder.file], new HttpHeaders()).subscribe(event => {
        if (event.type === HttpEventType.UploadProgress) {
          // This is an upload progress event. Compute and show the % done:
          this.progressBar.percent = Math.round(100 * event.loaded / event.total);
        } else if (event.type === HttpEventType.Response) {
          this.progressBar = {
            show: false,
            percent: 0
          }

          if (typeof this.initData == 'string') {
            this.initData = event.body.data.url;
          } else {
            this.initData.push(event.body.data.url);
          }
          this.initDataChange.emit(this.initData);
        }
      })
    }
  }

  getTypeOf(data) {
    return typeof data;
  }

  viewImage(idx) {
    let data = this.initData.map(i => {
      return { file: i }
    })
    this.showPopupViewImage(data, idx);
  }

  editFileSingle(idx) {
    if (this.initData) {
      if (this.fileTypes === '.pdf') {
        window.open(this.initData);
      } else {
        this.showPopupViewImage([{ file: this.initData }], idx);
      }
    } else {
      $('#' + this.idInput).click();
    }
  }

  showPopupViewImage(imgs, idx) {
    const modalRef = this.modalService.open(ViewimageComponent, { windowClass: 'view-image' });
    modalRef.componentInstance.imgs = imgs;
    modalRef.componentInstance.index = idx;
  }

  removeImageSingle() {
    this.initData = '';
    this.initDataChange.emit(this.initData);
  }

  getUrl(value): string {
    if (this.fileTypes == '.pdf') return '/assets/pdf-image.png';
    var noImage = '/assets/no_image.jpg';
    if (value != '') {
      try {
        if (value.indexOf('//') == -1) {
          return '/download?f=' + value;
        } else {
          return value;
        }
      } catch (err) {
        return noImage;
      }
    } else {
      return noImage;
    }
  }

  validateBeforeUpdate(fileTypeInput: string): boolean {
    console.log("fileTypeInput",fileTypeInput);
    console.log("this.fileTypes",this.fileTypes);

    //TODO: TH định dạng file không hề tồn tại => hệ thống ghi nhận fileInputTYpe = '' => false
    if (fileTypeInput === '') return false
    
    return this.fileTypes.includes(fileTypeInput)
  }
}
