export const AgentType = {
  0: "Vietlot",
  1: "Tổng đại lý",
  2: "Đại lý cấp 2",
  3: "Sale",
  4: "Điểm bán hàng",
  5: "RSM",
  6: "ASM",
  7: "Supervisor",
  8: "DSA",
  9: "CTV",
  12: "Consumer"
};

export const AgentTypeArea = [
  { id: 3, text: 'Sale' },
  { id: 6, text: 'ASM' },
  { id: 5, text: 'RSM' },
];

export const AgentTypeCode = {
  MASTER: 1,
  SUB: 2,
  SALE: 3,
  AGENT: 4,
  RSM: 5,
  ASM: 6,
  SUPERVISOR: 7,
  DSA: 8,
  CTV: 9
};
//
export const AgentTypeList = [
  { code: 0, type: 'kios', name: 'FINVIET' },
  { code: 1, type: 'kios', name: 'TĐL' },
  { code: 2, type: 'kios', name: 'ĐLC 2' },
  { code: 3, type: 'kios', name: 'Sale' },
  { code: 4, type: 'kios', name: 'ĐBH' },
  { code: 5, type: 'dsa', name: 'RSM' },
  { code: 6, type: 'dsa', name: 'ASM' },
  { code: 7, type: 'dsa', name: 'Supervisor' },
  { code: 8, type: 'dsa', name: 'DSA' },
  { code: 9, type: 'dsa', name: 'CTV' },
  { code: 10, type: 'other', name: "Leader" },
  { code: 11, type: 'kios', name: "3Party" },
  { code: -1, type: 'kios', name: 'Default' }

];
export const AgentTypeListCoCoBay = [
  { code: 0, type: 'kios', name: 'CCS' },
  { code: 4, type: 'kios', name: 'Merchain' },
];
export const AgentStatusList = [
  { code: 3, name: 'Đang hoạt động' },
  { code: 0, name: 'Đã mời - chưa đăng ký' },
  { code: 8, name: 'Đang tạm khóa' },
  { code: 9, name: 'Đã off' }
];

export const AgentClassList = [
  { code: 1, name: 'Đã đề xuất' },
  { code: 2, name: 'Đã kiểm tra' },
  { code: 3, name: 'Đã từ chối' },
  { code: 4, name: 'Đang hoạt động' }
];

export const Mbf8AgentTypeListForTree = [
  { code: 0, type: 'kios', name: "MBF8" },
  { code: 5, type: 'kios', name: "MBFT" },
  { code: 6, type: 'kios', name: "ASM" },
  { code: 1, type: 'kios', name: "Supervisor" },
  { code: 2, type: 'kios', name: "Sale" },
  { code: 4, type: 'kios', name: "ĐBH" },
];

export const Mbf2AgentTypeListForTree = [
  { code: 0, type: 'kios', name: "MBF2" },
  { code: 5, type: 'kios', name: "MBFT" },
  { code: 6, type: 'kios', name: "ASM" },
  { code: 1, type: 'kios', name: "Supervisor" },
  { code: 2, type: 'kios', name: "Sale" },
  { code: 4, type: 'kios', name: "ĐBH" },
];

export const LoanStatus = {
  0: "Hồ sơ mới",
  1: "Thẩm định thành công",
  2: "Thẩm định thất bại",
  3: "Thiếu hồ sơ",
  4: "Không đủ điều kiện vay",
  5: "Đã nhập liệu",
  6: "Đã giải ngân",
  7: "Đã thu hồi hồ sơ",
  8: "Khách hàng hủy hồ sơ",
  9: "Top-up",
  10: "Duplicated",
  11: "Gọi tư vấn & hẹn gặp lấy HS",
  12: "HS đang xử lý",
  13: "HS đang chờ khai thác",
  14: "Số điện thoại không liên hệ được",
  15: "Xác nhận không đúng thông tin khách hàng đăng ký vay",
  16: "Từ chối do KH có nợ xấu, nợ cần chú ý",
  17: "Từ chối do KH không có nhu cầu vay",
  18: "Từ chối do KH không đủ khả năng tài chính",
  19: "Từ chối do các thông tin KH khai báo không chính xác",
  20: "Từ chối do KH không thỏa điều kiện vay",
  21: "Từ chối do hồ sơ đăng ký vay không hợp lệ",
}

export const LoanPartnerStatus = {
  0: { name: 'Trong quá trình xử lý', loanStatus: 12 },
  1: { name: 'Đã duyệt sơ cấp', loanStatus: 12 },
  2: { name: 'Trong quá trình ra quyết định', loanStatus: 12 },
  3: { name: 'Từ chối', loanStatus: 2 },
  4: { name: 'Đã huỷ', loanStatus: 8 },
  5: { name: 'Chấp nhận/ Chờ duyệt', loanStatus: 12 },
  6: { name: 'Đã được chấp nhận', loanStatus: 12 },
  7: { name: 'Chấp nhận/ Chờ ký', loanStatus: 12 },
  8: { name: 'Trong quá trình ký', loanStatus: 12 },
  9: { name: 'Đã ký/ Chờ giải ngân', loanStatus: 12 },
  10: { name: 'Đang hiệu lực', loanStatus: 6 },
  11: { name: 'Đã chấm dứt', loanStatus: null },
}

export const TypeofWord = {
  8: 'Kinh doanh không giấy phép',
  9: 'Kinh doanh có giấy phép',
  10: 'Hưởng lương'
}

export const LoanStatusList = [
  {
    code: 0,
    system: { desc: 'Hồ sơ mới' },
    kios: { desc: 'Hồ sơ mới' },
    dsa: { desc: 'Hồ sơ mới' }
  },
  {
    code: 1,
    system: { desc: 'Thẩm định thành công' },
    kios: { desc: 'Đang xử lý', },
    dsa: { desc: 'App' },
    appId: true
  },
  {
    code: 2,
    system: { desc: 'Thẩm định thất bại' },
    kios: {
      desc: 'Từ chối cho vay',
      noti: 'Thông báo: Hồ sơ KH <<customer_name>> số đt <<customer_phone>> đã thẩm định thất bại'
    },
    dsa: {
      desc: 'Rejected',
      noti: 'Thông báo: Hồ sơ KH <<customer_name>> số đt <<customer_phone>> đã thẩm định thất bại'
    }
  },
  {
    code: 3,
    system: { desc: 'Thiếu hồ sơ', },
    kios: { desc: 'Đang xử lý', },
    dsa: { desc: 'App' }
  },
  {
    code: 4,
    system: { desc: 'Không đủ điều kiện vay' },
    kios: {
      desc: 'Từ chối cho vay',
      noti: 'Thông báo: Hồ sơ KH <<customer_name>> số đt <<customer_phone>> không đủ điều kiện vay theo quy định của ngân hàng'
    },
    dsa: {
      desc: 'Rejected',
      noti: 'Thông báo: Hồ sơ KH <<customer_name>> số đt <<customer_phone>> không đủ điều kiện vay theo quy định của ngân hàng'
    }
  },
  {
    code: 5,
    system: { desc: 'Đã nhập liệu' },
    kios: { desc: 'Đang xử lý' },
    dsa: { desc: 'App' },
    appId: true
  },
  {
    code: 6,
    system: { desc: 'Đã giải ngân' },
    kios: {
      desc: 'Được duyệt',
      noti: 'Chúc mừng Quý CH, hồ sơ KH <<customer_name>> số đt <<customer_phone>> đã được giải ngân. Hoa hồng giới thiệu KH quý CH sẽ nhận được là 500,000 đồng trong vòng 7 ngày kế tiếp'
    },
    dsa: {
      desc: 'Loan',
      noti: 'Chúc mừng hồ sơ KH <<customer_name>> số đt <<customer_phone>> đã được giải ngân. Hoa hồng giới thiệu KH quý CH sẽ nhận được là 500,000 đồng trong vòng 7 ngày kế tiếp'
    }
  },
  {
    code: 7,
    system: { desc: 'Đã thu hồi hồ sơ' },
    kios: { desc: 'Đang xử lý' },
    dsa: { desc: 'Lead' }
  },
  {
    code: 8,
    system: { desc: 'Khách hàng hủy hồ sơ' },
    kios: {
      desc: 'Từ chối cho vay',
      noti: 'Thông báo: Hồ sơ KH <<customer_name>> số đt <<customer_phone>> đã hủy. Lý do: <<reason>>',
      requireNote: true
    },
    dsa: {
      desc: 'Rejected',
      noti: 'Thông báo: Hồ sơ KH <<customer_name>> số đt <<customer_phone>> đã hủy. Lý do: <<reason>>',
      requireNote: true
    }
  },
  {
    code: 9,
    system: { desc: 'Top-up' },
    kios: {
      desc: 'Từ chối cho vay',
      noti: 'Thông báo: Hồ sơ KH <<customer_name>> số đt <<customer_phone>> không đủ điều kiện vay theo quy định của ngân hàng'
    },
    dsa: {
      desc: 'Rejected',
      noti: 'Thông báo: Hồ sơ KH <<customer_name>> số đt <<customer_phone>> không đủ điều kiện vay theo quy định của ngân hàng'
    }
  },
  {
    code: 10,
    system: { desc: 'Duplicated' },
    kios: {
      desc: 'Từ chối cho vay',
      noti: 'Thông báo: Hồ sơ KH <<customer_name>> số đt <<customer_phone>> không đủ điều kiện vay theo quy định của ngân hàng'
    },
    dsa: {
      desc: 'Rejected',
      noti: 'Thông báo: Hồ sơ KH <<customer_name>> số đt <<customer_phone>> không đủ điều kiện vay theo quy định của ngân hàng'
    }
  },
  {
    code: 11,
    system: { desc: 'Gọi tư vấn & hẹn gặp lấy HS' },
    kios: { desc: 'Đang xử lý' },
    dsa: { desc: 'Lead' }
  }, {
    code: 12,
    system: { desc: 'HS đang xử lý' },
    kios: { desc: 'Đang xử lý' },
    dsa: { desc: 'Lead' }
  }, {
    code: 13,
    system: { desc: 'HS đang chờ khai thác' },
    kios: { desc: 'Đang chờ khai thác' },
    dsa: { desc: 'Lead' }
  },
  {
    code: 14,
    system: { desc: 'Số điện thoại không liên hệ được' },
    kios: { desc: 'Đang xử lý' },
    dsa: { desc: 'Lead' }
  },
  {
    code: 15,
    system: { desc: 'Xác nhận không đúng thông tin khách hàng đăng ký vay' },
    kios: { desc: 'Đang xử lý' },
    dsa: { desc: 'Lead' }
  },
  {
    code: 16,
    system: { desc: 'Từ chối do KH có nợ xấu, nợ cần chú ý' },
    kios: {
      desc: 'Từ chối cho vay',
      noti: 'Thông báo: Hồ sơ KH <<customer_name>> số đt <<customer_phone>> không đủ điều kiện vay theo quy định của ngân hàng'
    },
    dsa: {
      desc: 'Rejected',
      noti: 'Thông báo: Hồ sơ KH <<customer_name>> số đt <<customer_phone>> không đủ điều kiện vay theo quy định của ngân hàng'
    }
  },
  {
    code: 17,
    system: { desc: 'Từ chối do KH không có nhu cầu vay' },
    kios: {
      desc: 'Từ chối cho vay',
      noti: 'Thông báo: Hồ sơ KH <<customer_name>> số đt <<customer_phone>> không đủ điều kiện vay theo quy định của ngân hàng'
    },
    dsa: {
      desc: 'Rejected',
      noti: 'Thông báo: Hồ sơ KH <<customer_name>> số đt <<customer_phone>> không đủ điều kiện vay theo quy định của ngân hàng'
    }
  },
  {
    code: 18,
    system: { desc: 'Từ chối do KH không đủ khả năng tài chính' },
    kios: {
      desc: 'Từ chối cho vay',
      noti: 'Thông báo: Hồ sơ KH <<customer_name>> số đt <<customer_phone>> không đủ điều kiện vay theo quy định của ngân hàng'
    },
    dsa: {
      desc: 'Rejected',
      noti: 'Thông báo: Hồ sơ KH <<customer_name>> số đt <<customer_phone>> không đủ điều kiện vay theo quy định của ngân hàng'
    }
  },
  {
    code: 19,
    system: { desc: 'Từ chối do các thông tin KH khai báo không chính xác' },
    kios: {
      desc: 'Từ chối cho vay',
      noti: 'Thông báo: Hồ sơ KH <<customer_name>> số đt <<customer_phone>> không đủ điều kiện vay theo quy định của ngân hàng'
    },
    dsa: {
      desc: 'Rejected',
      noti: 'Thông báo: Hồ sơ KH <<customer_name>> số đt <<customer_phone>> không đủ điều kiện vay theo quy định của ngân hàng'
    }
  },
  {
    code: 20,
    system: { desc: 'Từ chối do KH không thỏa điều kiện vay' },
    kios: {
      desc: 'Từ chối cho vay',
      noti: 'Thông báo: Hồ sơ KH <<customer_name>> số đt <<customer_phone>> không đủ điều kiện vay theo quy định của ngân hàng'
    },
    dsa: {
      desc: 'Rejected',
      noti: 'Thông báo: Hồ sơ KH <<customer_name>> số đt <<customer_phone>> không đủ điều kiện vay theo quy định của ngân hàng'
    }
  },
  {
    code: 21,
    system: { desc: 'Từ chối do hồ sơ đăng ký vay không hợp lệ' },
    kios: {
      desc: 'Từ chối cho vay',
      noti: 'Thông báo: Hồ sơ KH <<customer_name>> số đt <<customer_phone>> không đủ điều kiện vay theo quy định của ngân hàng'
    },
    dsa: {
      desc: 'Rejected',
      noti: 'Thông báo: Hồ sơ KH <<customer_name>> số đt <<customer_phone>> không đủ điều kiện vay theo quy định của ngân hàng'
    }
  }
];

export const LoanTypeList = [
  { id: 1, name: "Cho vay Cà vẹt xe" },
  { id: 2, name: "Cho vay Hóa đơn điện" },
  { id: 3, name: "Cho vay Hợp đồng lao động" },
  { id: 4, name: "Cho vay Bảo hiểm nhân thọ" }
];

export const LoanType = {
  1: "Cho vay Cà vẹt xe",
  2: "Cho vay Hóa đơn điện",
  3: "Cho vay Hợp đồng lao động",
  4: "Cho vay Bảo hiểm nhân thọ",
};
export const LoanTypePru = {
  1: "Vay theo Cà vẹt xe",
  2: "Vay theo Hóa đơn điện",
  3: "Vay theo Lương",
  4: "Vay theo Bảo hiểm nhân thọ",
  5: "Vay theo Thẻ tín dụng",
  6: "Vay theo HĐ tín dụng khác",
  7: "Gói vay khác"
};
export const InsuranceType = {
  1: "Gói cơ bản",
  2: "Gói nâng cao",
};
export const InsuranceList = [
  { id: 1, name: "Gói cơ bản" },
  { id: 2, name: "Gói nâng cao" },
];

export const LoanTypeListPru = [
  { id: 3, name: "Vay theo Lương" },
  { id: 4, name: "Vay theo Bảo hiểm nhân thọ" },
  { id: 5, name: "Vay theo Thẻ tín dụng" },
  { id: 1, name: "Vay theo Cà vẹt xe" },
  { id: 2, name: "Vay theo Hóa đơn điện" },
  { id: 6, name: "Vay theo HĐ tín dụng khác" },
  { id: 7, name: "Gói vay khác" }
];

export const LoanDocumentTypePru = {
  "idcard": 'CMND/ Passport',
  "curriculumvitae": 'Giấy tờ thứ cấp (Bằng cấp/ Thẻ nhân viên/SYLL/Bằng lái…)',
  "familybook": 'Hộ Khẩu',
  "permanentconfirmation": 'KT3/XNTT',
  "letterconfirm": 'Thư xác nhận',
  "payslip": 'Sao kê lương/ Bảng Lương',
  "laborcontracts": 'HĐLĐ/ XNCT/ Quyết định',
  "healthinsure": 'BHYT',
  "insure": 'Hợp đồng bảo hiểm nhân thọ',
  "receiptinsure": ' Biên lai đóng phí BHNT',
  "businesslicense": 'Giấy phép kinh doanh',
  "creditcard": 'Thẻ tín dụng',
  "creditcardstatement": 'Sao kê thẻ tín dụng',
  "electricbill": 'Hóa đơn điện',
  "certificatemotor": ' Cà vẹt xe',
  "creditcontractsother": 'HĐ tín dụng khác',
  "others": 'Giấy tờ khác',
};

export const LoanDocumentType = {
  "idcard1": 'CMND mặt trước',
  "idcard2": 'CMND mặt sau',
  "payslip": 'Sao kê lương',
  "bills": 'Hóa đơn điện',
  "insure": 'Hợp đồng bảo hiểm',
  "certificatemotor": 'Cà vẹt xe',
  "familybook": 'Hộ Khẩu',
  "loanrequest": 'Đề nghị vay',
  "laborcontracts": 'Hợp đồng lao động',
  "healthinsure": 'Thẻ bảo hiểm y tế',
  "others": 'Giấy tờ khác',
};

export const docNameList = [
  { id: 'idcard1', name: 'CMND mặt trước' },
  { id: 'idcard2', name: 'CMND mặt sau' },
  { id: 'certificatemotor', name: 'Cà vẹt xe' },
  { id: 'bills', name: 'Hóa đơn điện' },
  { id: 'payslip', name: 'Sao kê lương' },
  { id: 'laborcontracts', name: 'Hợp đồng lao động' },
  { id: 'insure', name: 'HĐ bảo hiểm nhân thọ' },
  { id: 'healthinsure', name: 'Thẻ bảo hiểm y tế' },
  { id: 'loanrequest', name: 'Đề nghị vay' },
  { id: 'familybook', name: 'Hộ khẩu gia đình' },
  { id: 'others', name: 'Giấy tờ khác' }
];
export const docNameListPru = [
  { id: 'idcard', name: 'CMND/ Passport' },
  { id: 'curriculumvitae', name: 'Giấy tờ thứ cấp (Bằng cấp/ Thẻ nhân viên/SYLL/Bằng lái…)' },
  { id: 'familybook', name: 'Hộ Khẩu' },
  { id: 'permanentconfirmation', name: 'KT3/XNTT' },
  { id: 'letterconfirm', name: 'Thư xác nhận' },
  { id: 'payslip', name: 'Sao kê lương/ Bảng Lương' },
  { id: 'laborcontracts', name: 'HĐLĐ/ XNCT/ Quyết định' },
  { id: 'healthinsure', name: 'BHYT' },
  { id: 'insure', name: 'Hợp đồng bảo hiểm nhân thọ' },
  { id: 'receiptinsure', name: 'Biên lai đóng phí BHNT' },
  { id: 'businesslicense', name: 'Giấy phép kinh doanh' },
  { id: 'creditcard', name: 'Thẻ tín dụng' },
  { id: 'creditcardstatement', name: 'Sao kê thẻ tín dụng' },
  { id: 'electricbill', name: 'Hóa đơn điện' },
  { id: 'certificatemotor', name: ' Cà vẹt xe' },
  { id: 'creditcontractsother', name: 'HĐ tín dụng khác' },
  { id: 'others', name: 'Giấy tờ khác' }
];

export const typeUpdate = {
  1: "Cập nhật trạng thái hồ sơ",
  2: "Cập nhật thông tin khách hàng",
  3: "Cập nhật thông tin khoản vay",
  4: "Cập nhật hình ảnh hồ sơ"
};
export const CommonStatus = [
  { name: 'Thành công', id: 1 },
  { name: 'Đang xử lý', id: 2 },
  { name: 'Thất bại', id: 6 }
];
export const BranchList = [
  { code: 'finviet', desc: 'Finviet', man_name: 'HỆ THỐNG QUẢN LÝ', anw_name: 'HỆ THỐNG PHÂN PHỐI' },
  { code: 'mbf8', desc: 'Mobifone 8', man_name: 'HỆ THỐNG QUẢN LÝ', anw_name: 'HỆ THỐNG PHÂN PHỐI' },
  { code: 'mbf2', desc: 'Mobifone 2', man_name: 'HỆ THỐNG QUẢN LÝ', anw_name: 'HỆ THỐNG PHÂN PHỐI' },
  { code: 'fv_ctv', desc: 'CTV Finviet', man_name: 'HỆ THỐNG QUẢN LÝ', anw_name: 'HỆ THỐNG PHÂN PHỐI' },
  { code: 'pru', desc: 'Pru', man_name: 'HỆ THỐNG QUẢN LÝ', anw_name: 'HỆ THỐNG PHÂN PHỐI' },
  { code: 'cocobay', desc: 'Cocobay', man_name: 'HỆ THỐNG QUẢN LÝ', anw_name: 'HỆ THỐNG THỐNG KÊ BÁO CÁO' },
];
export const typeAgentUpdate = {
  1: "Cập nhật thông tin đại lý",
  2: "Cập nhật tổng ĐL",
  3: "Cập nhật hình ảnh",
  4: "Cập nhật loại điểm bán",
  5: "Reset pin",
  6: "Cập nhật nhánh",
  7: "Duyệt ON điểm bán",
  8: "Cập nhật trạng thái điểm bán",
  9: "Thêm vào danh sách blacklist",
  10: "Xóa khỏi danh sách blacklist"
};
export const typeContractUpdate = {
  1: "Cập nhật trạng thái",
  2: "Gán sale"
};
export const Mbf8AgentTypeList = [
  { code: 0, label: "MBF8", top: false, type: 'kios' },
  { code: 5, label: "MBFT", top: false, type: 'kios' },
  { code: 6, label: "ASM", top: false, type: 'kios' },
  { code: 1, label: "Supervisor", top: true, type: 'kios' },
  { code: 2, label: "Sale", top: false, type: 'kios' },
  { code: 4, label: "ĐBH", top: false, type: 'kios' },
];

export const Telco = [
  {
    code: 'mbf', label: "MobiFone",
    topup: ['vtdd', 'vtc', 'epay', 'gsm', 'vnm'],
    postpaid: ['vtdd', 'vtc', 'epay', 'gsm', 'vnm'],
    buycard: ['vtc', 'epay', 'gsm', 'vnm']
  },
  {
    code: 'vnp', label: "Vinaphone",
    topup: ['epay', 'vtc', 'gsm', 'zopost', 'gsm', 'vnm'],
    postpaid: ['vtc', 'epay', 'gsm', 'zopost', 'vnm'],
    buycard: ['vtc', 'epay', 'gsm', 'zopost', 'vnm']
  },
  {
    code: 'vte', label: "Viettel",
    topup: ['gsm', 'vtc', 'epay', 'zopost', 'vnm'],
    postpaid: ['vtc', 'epay', 'gsm', 'zopost', 'vnm'],
    buycard: ['vtc', 'epay', 'zopost', 'vnm']
  },
  {
    code: 'gmb', label: "Gmobi",
    topup: ['vtc', 'epay', 'zopost', 'gsm', 'vnm'],
    postpaid: ['vtc', 'epay', 'gsm', 'zopost', 'vnm'],
    buycard: ['vtc', 'epay', 'gsm', 'zopost', 'vnm']
  },
  {
    code: 'vnm', label: "Vietnamobi",
    topup: ['vnm', 'vtc', 'epay', 'zopost', 'gsm'],
    postpaid: ['vtc', 'epay', 'gsm', 'zopost', 'vnm'],
    buycard: ['vtc', 'epay', 'gsm', 'zopost', 'vnm']
  },
];

export const Connector = [
  {
    code: 'epay',
    label: 'Epay'
  },
  {
    code: 'vteapp',
    label: 'VTE App'
  },

  {
    code: 'gsm',
    label: 'GSM',
  },
  {
    code: 'vtc',
    label: 'VTC',
  },
  {
    code: 'vtdd',
    label: 'VTDD',
  },
  {
    code: 'vnm',
    label: 'VNMobile',
  },
  {
    code: 'zopost',
    label: 'Zopost',
  },
  {
    code: 'gmb',
    label: 'GMobile',
  },
  {
    code: 'mbf',
    label: 'Mobifone',
  },
  {
    code: 'iomedia',
    label: 'IO-Media',
  },
  {
    code: 'vnp',
    label: 'Vinaphone',
  },
  {
    code: 'mbf2',
    label: 'Mobifone2',
  },
  {
    code: 'vteapp',
    label: 'Viettel App',
  },
  {
    code: 'xpay2',
    label: 'Xpay2',
  },
  {
    code: 'thetouch',
    label: 'TheTouch',
  },
  {
    code: 'sim',
    label: 'Simulator',
  },
  {
    code: 'epay2',
    label: 'Epay2'
  },
  {
    code: 'vtc2',
    label: 'VTC2'
  },
  {
    code: 'wpay',
    label: 'WPAY'
  },
];

export const AgentTypes = {
  0: "FINVIET",
  4: "ĐBH",
  12: "Consumer",
  1: "TĐL",
  2: "ĐLC 2",
  3: "Sale",
  5: "RSM",
  6: "ASM",
  7: "Supervisor",
  10: "Leader",
  11: "3P Party",
  8: "DSA",
  9: "CTV",
};

export const AgentTypesCoCoBay = {
  0: "CCS",
  4: "Merchain"
};

export const AgentTypesMbf8 = {
  0: "MBF8",
  5: "MBFT",
  6: "ASM",
  1: "Supervisor",
  2: "Sale",
  4: "ĐBH"
};

export const AgentTypesMbf2 = {
  0: "MBF2",
  5: "MBFT",
  6: "ASM",
  1: "Supervisor",
  2: "Sale",
  4: "ĐBH"
};

export const transType = [
  { id: "BUY", name: "Nạp điện thoại", cmd: 6 },
  { id: "TRANSFER", name: "Nhận/Chuyển tiền", cmd: 4 },
  { id: "ADJUSTMENT", name: "Điều chỉnh" },
  { id: "BONUS", name: "Hoa hồng" },
  { id: "BUYCODE", name: "Mã thẻ", cmd: 29 },
  { id: "BILLPAY", name: "TT hóa đơn", cmd: 7 },
  { id: "POSTPAID", name: "Trả sau", cmd: 28 },
  { id: "CASHIN", name: "Nạp Tiền", cmd: 203 },
  { id: "CASHOUT", name: "Rút tiền", cmd: 204 },
  { id: "BUYINSU", name: "Mua bảo hiểm", cmd: 56 },
  { id: "USECARD", name: "Gạch thẻ", cmd: 58 },
  { id: "PAYECOM", name: "Mua hàng", cmd: 302 },
];
export const statusLoans = [
  { id: 0, name: 'Hồ sơ mới' },
  { id: 1, name: 'Thẩm định thành công' },
  { id: 2, name: 'Thẩm định thất bại' },
  { id: 3, name: 'Thiếu hồ sơ' },
  { id: 4, name: 'Không đủ điều kiện vay' },
  { id: 5, name: 'Đã nhập liệu' },
  { id: 6, name: 'Đã giải ngân' },
  { id: 7, name: 'Đã thu hồi hồ sơ' },
  { id: 8, name: 'Khách hàng hủy hồ sơ' },
  { id: 9, name: 'Top-up' },
  { id: 10, name: 'Duplicated' },
  { id: 11, name: 'Gọi tư vấn & hẹn gặp lấy HS' },
  { id: 12, name: 'HS đang xử lý' },
  { id: 13, name: 'HS đang chờ khai thác' }
];
export const cashoutStatus = [
  { id: 0, name: 'Yêu cầu mới' },
  { id: 2, name: 'Đang xử lý' },
  { id: 3, name: 'Yêu cầu bị hủy' },
  { id: 1, name: 'Đã chuyển tiền' }
];
export const businessType = [
  { id: 0, name: 'Cửa hàng sim thẻ' },
  { id: 1, name: 'Điện thoại di động' },
  { id: 2, name: 'Thiết bị điện tử' },
  { id: 3, name: 'Đồ gia dụng' },
  { id: 4, name: 'Xe máy' },
  { id: 5, name: 'Tiệm vàng' },
  { id: 6, name: 'Tạp hóa' },
  { id: 7, name: 'Internet' },
  { id: 8, name: 'Nhà thuốc' },
  { id: 9, name: 'Cửa hàng thời trang' },
  { id: 10, name: 'Cửa hàng mỹ phẩm' },
  { id: 11, name: 'Cửa hàng gạo Tân Long' },
  { id: 12, name: 'Cửa hàng ăn uống' },
  { id: 13, name: 'Khách hàng nội bộ' },
  { id: 100, name: 'Loại hình khác' }
];
export const locationType = [
  { id: 0, name: 'Gần các chợ' },
  { id: 1, name: 'Khu công nghiệp' },
  { id: 2, name: 'Khu ở trọ công nhân' },
  { id: 3, name: 'Cụm công nghiệp nhỏ' },
  { id: 4, name: 'Nhà máy chế biến' },
  { id: 5, name: 'Khu dân cư' },
  { id: 6, name: 'Trục đường chính' },
  { id: 7, name: 'Hẻm xe máy' },
  { id: 8, name: 'Hẻm xe tải' },
];
export const agentCategory = [
  { id: 0, name: 'Loại 0' },
  { id: 1, name: 'Loại 1' },
  { id: 2, name: 'Loại 2' },
  { id: 3, name: 'Loại 3' },
];

export const contractStatus = {
  NEW: 0,
  ASSIGNED: 1,
  SALE_RECEIVE: 2,
  APPOINTMENT: 3,
  SALE_REQUEST_CANCEL: 4,
  REQUEST_CANCEL: 5,
  CANCELED: 6,
  SALE_UPLOADED_LIST: 7,
  UPLOADED_LIST: 8,
  UPLOADED_LIST_FAIL: 9,
  UPLOADED_LIST_DONE: 10,
  SEND_DOC: 11,
  SEND_DOC_FAIL: 12,
  FINISH: 13
}
//
export const contractStatusMap = {
  0: 'Mới tạo',
  1: 'Đã gán sale',
  2: 'Sale đã tiếp nhận',
  3: 'KH hẹn lại',
  4: 'Sale Đề xuất hủy',
  5: 'Đã gửi yêu cầu hủy qua EVNFC',
  6: 'Đã hủy',
  7: 'Sale Gửi duyệt file scan',
  8: 'Đã gửi yêu cầu duyệt file scan qua EVNFC',
  9: 'Đã gửi duyệt file scan fail',
  10: 'Đã duyệt file scan thành công',
  11: 'Đã gởi bản cứng',
  12: 'Đã duyệt bản cứng fail',
  13: 'Hoàn thành',
}
export const typeInsuBuyUpdate = {
  1: "Cập nhật thông tin KH",
  2: "Cập nhật giấy tờ"
};
export const AgentTransfer = {
  'mbf.error': "Trả lỗi Mobifone",
  'vnm.error': "Trả lỗi Vietnam mobile",
  'vte.error': "Trả lỗi Viettel",
  'vnp.error': "Trả lỗi Vinaphone",
  'gmb.error': "Trả lỗi GMobile",
};
export const SchedulerStatus = {
  0: 'Mới tạo',
  1: 'Đã duyệt',
  2: 'Từ chối',
  3: 'Hủy',
  4: 'Force stop'
}
export const typeUpdateNoti = {
  1: "Cập nhật thông tin",
  2: "Cập nhật trạng thái",
};

export const SimStatusList = [
  /*{
    code: 0,
    system: {desc: 'Hồ sơ mới'},
    kios: {desc: 'Hồ sơ mới'},
    dsa: {desc: 'Hồ sơ mới'}
  },*/
  {
    code: 1,
    system: { desc: 'Đang xử lý' },
    kios: { desc: 'Đang xử lý' },
    dsa: { desc: 'Đang xử lý' }
  },
  {
    code: 2,
    system: { desc: 'Thành công' },
    kios: { desc: 'Thành công' },
    dsa: { desc: 'Thành công' }
  },
  /*{
    code: 5,
    system: {desc: 'Hết thời gian chờ'},
    kios: {desc: 'Hết thời gian chờ'},
    dsa: {desc: 'Hết thời gian chờ'}
  },*/
  {
    code: 6,
    system: { desc: 'Thất bại' },
    kios: { desc: 'Thất bại' },
    dsa: { desc: 'Thất bại' }
  }
];


export const VasStatusList = [
  {
    code: 'USER_YES',
    system: { desc: 'Thành công' },
    kios: { desc: 'Thành công' },
    dsa: { desc: 'Thành công' }
  },
  {
    code: 'USER_FAILED',
    system: { desc: 'ĐK thất bại' },
    kios: { desc: 'ĐK thất bại' },
    dsa: { desc: 'ĐK thất bại' }
  },
  {
    code: 'USER_NO',
    system: { desc: 'KH từ chối' },
    kios: { desc: 'KH từ chối' },
    dsa: { desc: 'KH từ chối' }
  },
  {
    code: 'USER_RECEIVED',
    system: { desc: 'Chờ KH xác nhận' },
    kios: { desc: 'Chờ KH xác nhận' },
    dsa: { desc: 'Chờ KH xác nhận' }
  },
  {
    code: 'REQ_FAILED',
    system: { desc: 'Lỗi' },
    kios: { desc: 'Lỗi' },
    dsa: { desc: 'Lỗi' }
  }
];

export const InstallmentLoanStatus = [
  { id: 'INIT', name: 'Hồ sơ mới' },
  { id: 'SCORING', name: 'Đang xử lý' },
  { id: 'SCORING_SUCCESS', name: 'Thẩm định thành công' },
  { id: 'SCORING_FAIL', name: 'Thẩm định thất bại' },
  { id: 'EDIT_DOCUMENT', name: 'Thiếu hồ sơ' },
  { id: 'EDITED_DOCUMENT', name: 'Đã bổ sung hồ sơ' },
  { id: 'AWAITING_APPROVE', name: 'Đang chờ duyệt' },
  { id: 'APPROVED', name: 'Được duyệt' },
  { id: 'UN_APPROVED', name: 'Không duyệt hồ sơ' },
  { id: 'AWAITING_UPLOAD', name: 'Đợi bổ sung chứng từ giải ngân' },
  { id: 'UPLOADED', name: 'Đã bổ sung chứng từ giải ngân' },
  { id: 'AWAITING_EDIT_UPLOAD', name: 'Đợi sửa đổi bổ sung chứng từ giải ngân' },
  { id: 'EDITED_UPLOADED', name: 'Đã sửa đổi bổ sung chứng từ giải ngân' },
  { id: 'AWAITING_COMPLETED', name: 'Đang chờ duyệt giải ngân' },
  { id: 'COMPLETED', name: 'Đã giải ngân' },
  { id: 'REJECT_APPROVED', name: 'Từ chối phê duyệt' },
  { id: 'REJECT_COMPLETED', name: 'Từ chối giải ngân' },
  { id: 'CANCELED', name: 'Hủy hồ sơ' }
];

export const InstallmentAgentTypes = [
  { id: "99", name: "Tất cả", text: "Tất cả" },
  { id: 1, name: "MIRAE ASSET", text: "MIRAE ASSET" },
  { id: 2, name: "FE CREDIT", text: "FE CREDIT" }
]

export const InstallmentLoanDocumentType = {
  "image_selfie": 'Chân dung khách hàng chụp tại chỗ',
  "image_identity_cart_front": 'Mặt trước CMND/ Passport',
  "image_identity_cart_back": 'Mặt sau CMND/ Passport',
  "image_household": 'Hộ Khẩu/KT3',
  "image_driving_license_front": 'Mặt trước bằng lái xe',
  "image_driving_license_back": 'Mặt sau bằng lái xe',
  "image_receipt": 'Hóa đơn',
  "image_other": 'Giấy tờ khác',
};

export const InstallmentLoanDocumentPru = [
  { id: 'image_selfie', name: 'CMND/ Passport' },
  { id: 'image_identity_cart_front', name: 'Mặt trước CMND/ Passport' },
  { id: 'image_identity_cart_back', name: 'Mặt sau CMND/ Passport' },
  { id: 'image_household', name: 'Hộ Khẩu/KT3' },
  { id: 'image_driving_license_front', name: 'Mặt trước bằng lái xe' },
  { id: 'image_driving_license_back', name: 'Mặt sau bằng lái xe' },
  { id: 'image_receipt', name: 'Hóa đơn' },
  { id: 'image_other', name: 'Giấy tờ khác' }
];

export const BusinessCardType = [
  { id: 1, name: 'Giấy phép kinh doanh' }
]

export const LoanReferralChannelType = {
  hsv: {
    id: 'hsv',
    name: 'Kênh MAFC',
    val: 'hsv',
  },
  mc: {
    id: 'mc',
    name: 'Kênh MC',
    val: 'mc',
  },
}

export const list_issue_place_cardid = [
  { id: 103, name: "BỘ CÔNG AN" },
  { id: 101, name: "Cục Cảnh sát quản lý hành chính về TTXH" },
  { id: 100, name: "Cục Cảnh sát ĐKQL cư trú và DLQG về dân cư" },
  { id: 101, name: "Cục Cảnh sát quản lý hành chính về TTXH" },
  { id: 102, name: "Cục quản lý xuất nhập cảnh" },
  { id: 1, name: "Hồ Chí Minh" },
  { id: 2, name: "Hà Nội" },
  { id: 3, name: "An Giang" },
  { id: 5, name: "Bắc Kạn" },
  { id: 6, name: "Bắc Giang" },
  { id: 7, name: "Bạc Liêu" },
  { id: 8, name: "Bắc Ninh" },
  { id: 9, name: "Bến Tre" },
  { id: 11, name: "Bình Định" },
  { id: 12, name: "Bình Phước" },
  { id: 13, name: "Bình Thuận" },
  { id: 14, name: "Cà Mau" },
  { id: 15, name: "Cần Thơ" },
  { id: 16, name: "Cao Bằng" },
  { id: 17, name: "Đà Nẵng" },
  { id: 18, name: "Đắk Lắk" },
  { id: 19, name: "Điện Biên" },
  { id: 20, name: "Đồng Nai" },
  { id: 21, name: "Gia Lai" },
  { id: 22, name: "Hà Giang" },
  { id: 23, name: "Hà Nam" },
  { id: 24, name: "Hậu Giang" },
  { id: 25, name: "Hà Tĩnh" },
  { id: 26, name: "Hải Dương" },
  { id: 27, name: "Hải Phòng" },
  { id: 28, name: "Hòa Bình" },
  { id: 30, name: "Khánh Hòa" },
  { id: 32, name: "Lai Châu" },
  { id: 33, name: "Lâm Đồng" },
  { id: 34, name: "Lạng Sơn" },
  { id: 35, name: "Lào Cai" },
  { id: 36, name: "Long An" },
  { id: 37, name: "Nam Định" },
  { id: 38, name: "Nghệ An" },
  { id: 39, name: "Ninh Thuận" },
  { id: 40, name: "Phú Thọ" },
  { id: 41, name: "Phú Yên" },
  { id: 42, name: "Quảng Bình" },
  { id: 43, name: "Quảng Nam" },
  { id: 44, name: "Quảng Ngãi" },
  { id: 45, name: "Quảng Ninh" },
  { id: 46, name: "Quảng Trị" },
  { id: 47, name: "Sóc Trăng" },
  { id: 48, name: "Tây Ninh" },
  { id: 49, name: "Thái Bình" },
  { id: 50, name: "Thái Nguyên" },
  { id: 51, name: "Thanh Hóa" },
  { id: 52, name: "Huế" },
  { id: 53, name: "Tiền Giang" },
  { id: 54, name: "Trà Vinh" },
  { id: 55, name: "Tuyên Quang" },
  { id: 56, name: "Kiên Giang" },
  { id: 57, name: "Vĩnh Phúc" },
  { id: 58, name: "Bà Rịa–Vũng Tàu" },
  { id: 59, name: "Yên Bái" },
  { id: 60, name: "Vĩnh Long" },
  { id: 61, name: "Bình Dương" },
  { id: 62, name: "Đắk Nông" },
  { id: 63, name: "Đồng Tháp" },
  { id: 64, name: "Hưng Yên" },
  { id: 65, name: "Kon Tum" },
  { id: 66, name: "Ninh Bình" },
  { id: 67, name: "Sơn La" },
]