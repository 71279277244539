/**
 * Created by ty.pham on 07/06/2018.
 */
import {Component, ViewEncapsulation} from '@angular/core';
import {Router} from "@angular/router";

@Component({
  selector: 'app-cocobay-full',
  templateUrl: './cocobay-layout.component.html',
  styleUrls: ['./cocobay-layout.component.css']
})
export class CocobayLayoutComponent {
  currentPath = '';
  constructor(private router: Router){
    this.currentPath = this.router.url;
  }
}
