import { Directive, ElementRef, Renderer2, ViewChild, ContentChild, AfterContentInit } from '@angular/core';
import { NgModel } from '@angular/forms';

@Directive({
  selector: '[isRequired]'
})
export class RequiredValidatorDirective implements AfterContentInit {
  element: ElementRef;
  renderer: Renderer2;
  @ContentChild(NgModel) input: NgModel;

  constructor(element: ElementRef, renderer: Renderer2) {
    this.element = element;
    this.renderer = renderer;
  }

  ngAfterContentInit() {
    this.input.valueChanges.subscribe(() => {
      if (this.input.invalid  && (this.input.dirty || this.input.touched)) {
        this.addErrorClass();
      } else {
        this.removeErrorClass();
      }
    })
  }

  addErrorClass(): void {
    this.renderer.addClass(this.element.nativeElement, 'error');
  }

  removeErrorClass(): void {
    this.renderer.removeClass(this.element.nativeElement, 'error');
  }
}