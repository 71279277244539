import {Component, OnInit, Input, ViewEncapsulation} from '@angular/core';
import {NgbActiveModal} from "@ng-bootstrap/ng-bootstrap";
import {ApiService} from "../../../api.service";
import {AuthService} from "../../../auth.service";
import {PopupService} from "../../popup/popup.service";
import {NppRequest} from '../../../npp-request';
import {NppResponse} from '../../../npp-response';

@Component({
  selector: 'agent-balance',
  templateUrl: './agent-balance.component.html',
  styleUrls: ['./agent-balance.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class AgentBalanceComponent implements OnInit {
  _fields : any = {};
  _currentBalance: number;
  _loading: boolean = false;
  _loadingImg: string = '../../../assets/loading.gif';
  constructor(public activeModal: NgbActiveModal, private apiService: ApiService) {
    let from = new Date(new Date().toDateString());
    this._fields = {
      from : {year:from.getFullYear(),month:from.getMonth()+1,day:from.getDate()-1},
      time_from: 0,
      time_to: 0,
    };
  }

  ngOnInit() {
    this._fields.time_from = new Date(this._fields.from.year,this._fields.from.month-1,this._fields.from.day).setHours(0,0,0,0);
    this._fields.time_to = new Date(this._fields.from.year,this._fields.from.month-1,this._fields.from.day).setHours(23,59,59,999);
    this.queryBalance()
  }

  queryBalance() {
    this._loading = true
    this.apiService.postRequest(new NppRequest('security', 'currentuser', 'queryBalance', {fromDate: this._fields.time_from, toDate: this._fields.time_to}))
      .subscribe((response: NppResponse) => {
        this._loading = false
        if (response.data) {
          this._currentBalance = response.data
        } else {
          this._currentBalance = -1
        }
      })
  }

  hide() {
  }

  onChange(){
    let _f = this._fields;
    return function (e) {
      _f.from = e
      _f.time_from = new Date(_f.from.year,_f.from.month-1,_f.from.day).setHours(0,0,0,0);
      _f.time_to = new Date(_f.from.year,_f.from.month-1,_f.from.day).setHours(23,59,59,999);
    }
  }
}
