import {BrowserModule} from '@angular/platform-browser';
import {NgModule, APP_INITIALIZER} from '@angular/core';
import {FormsModule} from '@angular/forms';

import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {LoginComponent} from './login.component';
import {ApiService} from "./api.service";
import {AlertService} from "./alert.service";
import {AlertComponent} from "./alert.component";
import {HomeComponent} from "./home.component";
import {AuthGuard} from "./auth.guard";
import {StartupService} from "./startup.service";
import {AuthService} from "./auth.service";
import {NgbModule} from "@ng-bootstrap/ng-bootstrap";
import {NppCommonModule} from "./npp-common/npp-common.module";
import {FileService} from "./file.service";
import {PopupService} from "./npp-common/popup/popup.service";
import {FullLayoutComponent} from "./layouts/full-layout.component";
import {NoSideBarLayoutComponent} from "./layouts/no-sidebar-layout.component";
import {PageNotFoundComponent} from "./page-not-found.component";
import {Script} from "./script.service";
import {BrowserAnimationsModule} from "@angular/platform-browser/animations";
import {NppToasterService} from './npp-common/toaster/toaster.service'
import {NppBroadcast} from "./npp-common/broadcast/broadcast";
import {ToastrModule} from "ngx-toastr";
import {HttpClientModule} from "@angular/common/http";
import {NppBaseComponent} from "./base.component";
import {CocobayLayoutComponent} from './layouts/cocobay-layout.component';
import {UserProfileComponent} from './admin/user/my-profile/my-profile.component';
import {AgentBankingLayoutComponent} from "./agent-banking/common/layout/layout.component";
import { ChartsModule } from 'ng2-charts/ng2-charts';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
export function Startup(startupService: StartupService) {
  return () => startupService.load();
}


@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    PageNotFoundComponent,
    AlertComponent,
    HomeComponent,
    FullLayoutComponent,
    NoSideBarLayoutComponent,
    NppBaseComponent,
    CocobayLayoutComponent,
    UserProfileComponent,
    AgentBankingLayoutComponent
  ],
  imports: [
    BrowserModule,
    FormsModule,
    HttpClientModule,
    AppRoutingModule,
    NgbModule.forRoot(),
    BrowserAnimationsModule,
    NppCommonModule,
    ChartsModule,
    ToastrModule.forRoot({
      closeButton: true
    }),
    ServiceWorkerModule.register('ngsw-worker.js', { enabled: true }),
  ],
  providers: [
    ApiService,
    FileService,
    AlertService,
    AuthGuard,
    AuthService,
    StartupService,
    PopupService,
    NppToasterService,
    NppBroadcast,
    Script,
    {
      provide: APP_INITIALIZER,
      useFactory: Startup,
      deps: [StartupService],
      multi: true
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {

}
