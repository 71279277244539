import {AfterViewInit, Component, Input, OnInit, ViewEncapsulation} from '@angular/core';
import {MenuItem} from "../menu-item";
import {AuthService} from "../../../auth.service";
import {Router} from "@angular/router";
import {PopupService} from "../../popup/popup.service";
import {NppRequest} from '../../../npp-request';
import {NppResponse} from '../../../npp-response';
import {ApiService} from '../../../api.service';

@Component({
  selector: 'cocobay-sidebar',
  templateUrl: './cocobay-sidebar.component.html',
  styleUrls: ['./cocobay-sidebar.component.css'],
  encapsulation: ViewEncapsulation.None
})

export class CocobaySidebarComponent implements OnInit, AfterViewInit {

  @Input()
  open: boolean = true;

  public sidebarMenuItems: MenuItem[] = [];

  checkAction:any = {};
  currentRoute = '';
  avail_balance: string = '';

  constructor(private popupService: PopupService, private authService: AuthService, private router: Router, private apiService: ApiService) {
    router.events.subscribe((val: any) => {
      this.currentRoute = val.url;
    });
    this.checkAction.createNoti = this.authService.checkAction('cocobay', 'report-summary', 'getAvailBalance');

    var view = [];
    var item = this.router.url.split('/');
    view.push(item[1]);
    var obj = this.authService.currentUser.pages[view[0]]['children'];
    for (var i = 0; i < obj.length; i++) {
      if (obj[i]['show_menu']) {
        var menuItemObject = {
          name: obj[i]['name'],
          icon: obj[i]['icon'],
          route: '/' + view[0] + '/' + obj[i]['path'],
          actions: obj[i]['actions'],
          order: obj[i]['order'],
          level: obj[i]['parent_page']? 'level2': '',
        }
        this.sidebarMenuItems.push(menuItemObject);
      }
    }
    this.sidebarMenuItems.sort((a:any, b:any) => {return (a.order - b.order)})
  }

  ngOnInit() {
    if(this.checkAction.createNoti){
      this.getAvailBalance();
    }
  }

  showHideNavigation() {
    $(".wrap-sidebar,.wrapper").toggleClass("active");
  }

  clearSession(url) {
    // let urlView = sessionStorage.getItem('url')
    // let database = sessionStorage.getItem('database');
    // let dataView = sessionStorage.getItem('dataView');
    // if (urlView) {
    //   if (urlView == 'eco_app_settings_bank') {
    //     let parseNumber = this.checkSettingBank(JSON.parse(dataView));
    //     parseNumber['card_prefix'] = $("#tags").val();
    //     dataView = JSON.stringify(parseNumber)
    //
    //   } else if (urlView == 'loan_detail') {
    //     let parseNumber = this.checkLoanDetail(JSON.parse(dataView));
    //     dataView = JSON.stringify(parseNumber)
    //
    //   } else if (urlView == 'vendor_detail') {
    //     let parseNumber = this.checkVendorDetail(JSON.parse(dataView));
    //     dataView = JSON.stringify(parseNumber)
    //   }
    //   if (database && dataView && database.length != dataView.length) {
    //     this.popupService.showConfirm('Bạn đã có thay đổi, bạn có muốn thực hiện lưu thay đổi đó?').then(valPopup => {
    //       if (!valPopup) {
    //         sessionStorage.clear();
    //         this.router.navigate([url], {queryParams: {}});
    //       }
    //     });
    //   } else {
    //     sessionStorage.clear();
    //     this.router.navigate([url], {queryParams: {}});
    //   }
    //
    // } else {
    //   sessionStorage.clear();
    //   this.router.navigate([url], {queryParams: {}});
    // }
  }

  checkSettingBank(bank) {
    bank.cashout.static_fee = Number(bank.cashout.static_fee);
    bank.cashin.static_fee = Number(bank.cashin.static_fee)
    return bank;
  }

  checkLoanDetail(loan) {
    for (let i = 0; i < loan.packages.length; i++) {
      loan.value_min = Number(loan.value_min);
      loan.value_max = Number(loan.value_max);
      if (loan.packages) {
        for (let j = 0; j < loan.packages.length; j++) {
          let loanPackages = loan.packages[j];
          loanPackages.salary_min = Number(loanPackages.salary_min);
          loanPackages.salary_max = Number(loanPackages.salary_max);
          if (loanPackages.salary_range) {
            for (let k = 0; k < loanPackages.salary_range.length; k++) {
              let salary_range = loan.packages[j].salary_range[k];
              salary_range.value_min = Number(salary_range.value_min);
              salary_range.value_max = Number(salary_range.value_max);
              if (salary_range.loan_amount) {
                salary_range.loan_amount.min = Number(salary_range.loan_amount.min);
                salary_range.loan_amount.max = Number(salary_range.loan_amount.max);
              }
              if (salary_range.period) {
                salary_range.period.min = Number(salary_range.period.min);
                salary_range.period.max = Number(salary_range.period.max);
              }
            }
          }
        }
      }
      return loan;
    }
  }

  checkVendorDetail(vendor) {
    for (let i = 0; i < vendor.packages.length; i++) {
      vendor.packages[i].interest_dow_min = Number(vendor.packages[i].interest_dow_min);
      vendor.packages[i].interest_dow_max = Number(vendor.packages[i].interest_dow_max);
      vendor.packages[i].loan_min = Number(vendor.packages[i].loan_min);
      vendor.packages[i].loan_max = Number(vendor.packages[i].loan_max);
      vendor.packages[i].loanterm_min = Number(vendor.packages[i].loanterm_min);
      vendor.packages[i].loanterm_max = Number(vendor.packages[i].loanterm_max);
    }
    return vendor;
  }

  getAvailBalance(){
    var nppReq = new NppRequest('cocobay', 'report-summary', 'getAvailBalance', {});
    this.apiService.postRequest(nppReq).subscribe((res: NppResponse) => {
      if (res && res.result == 0) {
       this.avail_balance = res.data.avail_balance.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.");
      }
    });
  }

  logout(){
    this.authService.logout();
  }

  ngAfterViewInit(){
    $(document).on('click', '.cocobay-sidebar .icon-toggle', function($event){
      $('.cocobay-sidebar').toggleClass('show');
      $('.cocobay-wrapper').toggleClass('extended');
      $event.stopImmediatePropagation()
    })

    if($(window).width() <= 768){
      $('.cocobay-sidebar').removeClass('show');
    }
  }
}
