import { Component, ContentChild, ElementRef, Input, OnInit, Output, TemplateRef, ViewChild, EventEmitter } from '@angular/core';
import { Router } from "@angular/router";
import { ApiService } from 'app/api.service';
import { NppRequest } from 'app/npp-request';
import * as XLSX from 'xlsx';
import * as moment from 'moment';
import { deepKeyInObj } from 'app/agent-banking/helper';


@Component({
  selector: 'v2-datatable',
  templateUrl: './datatable2.component.html',
  styleUrls: ['./datatable2.component.css']
})
export class DataTable2Component implements OnInit {
  @ViewChild('TABLE') table: ElementRef;
  @ViewChild('searchInput') searchInput: ElementRef;
  @Input() actionType: string = 'POST';
  @Input() actionDataType: string = 'json';
  @Input() actionURL: string = window.location.href;
  @Input() actionParams: string | Object;
  @Input() fileName = 'ExcelSample';
  @Input() sheetName = 'Sheet1';
  @Input() exportType: 'local' | 'server' = 'local';
  @Input() searchPlaceHolder = 'Tìm trong bảng này';
  @Input() btnExport: boolean = true;
  @Input() directFetch: boolean = true;
  @Input() customTypeTable = '';
  @Input() showPagination: boolean = true;
  @Input() limitOptions: Array<number> = [20, 25, 50, 100];
  @Input() selectedLimit: number = 20;
  @Input() showIndex = true;
  @Input() usingOtherApi = true;
  @Input() headers = [];
  @Input() bodies = [];
  @Input() exceptList = [];
  @Input() wishListHeader: any = null;
  @Input() wishListBody: any = null;
  @Input() colSpanLoad: number;
  @Input() resHeader: string = 'data.headers';
  @Input() resBody: string = 'data.rows';
  @Input() hasActionHeader = true;
  @Input() countManual = 0;
  @Input() showFilterBar = true;
  @Input() heightTable = '100%';
  @Input() headerStyle = { "text-align": "center" };
  @Input() isNullInject = false;
  @Input() formatStatus = true;
  @Input() sortField = [{
    pos: 0, // position of field in list headers , start from 0 -> list.length - 1
    field: '', // field need sort
    sortType: '', // asc or desc
    sortVal: 1, // 1 or -1
  }];
  @Input() defaultSortBy = 'asc';
  @Input() showSearch = true;
  @Input() customFilter = [];
  @Input() argsOtherApi: any;
  @Input() inputClass: string = null;
  @Output() bodyOutside = new EventEmitter();
  @ContentChild('headRef') headRef: TemplateRef<any>;
  @ContentChild('bodyRef') bodyRef: TemplateRef<any>;

  currentPath = '';
  isLoading = true;
  isNullData = true;
  nullDataMsg = "Không có dữ liệu";
  currPage = 1;
  fromItem = 0;
  toItem = 0;
  totalItem = 0;
  totalPage = 0;
  exportData = [];
  fromdate;
  todate;
  limitDate;
  incrIcon = 'fa-sort-asc';
  decrIcon = 'fa-sort-desc';

  private keepOriginalBody = [];

  constructor(private router: Router, private apiService: ApiService) {
    this.currentPath = this.router.url;
    this.actionParams = '';
    this.todate = moment(moment.now()).format('yyyy-MM-DD');
    this.limitDate = this.todate;
  }

  ngOnInit() {
    if (this.directFetch) this.fetchDataDirectly({
      args: this.argsOtherApi
    });
  }

  //#region START MAIN FUNCTION

  fetchDataDirectly({ args = null, isExporting = false, reload = false }) {
    try {
      this.colSpanLoad = this.headers.length + (this.hasActionHeader ? 2 : 1); // If remove Stt (default stt is set) then colSpanLoad = this.header.length , else + 1;
      if (!isExporting) this.isLoading = true;
      if (this.showPagination == false) this.selectedLimit = 1000;
      if (this.usingOtherApi && args) {
        if (!isExporting) {
          args['params']['page'] = this.currPage;
          args['params']['pagesize'] = this.selectedLimit;
        } else {
          args['params']['page'] = 0;
          args['params']['pagesize'] = 0;
        }

        var nppReq = new NppRequest(args['module'], args['page'], args['action'], args['params']);
        return new Promise(resolve => {
          this.apiService.postRequest(nppReq).subscribe(
            response => {
              if (response.result !== 0) {
                this.nullDataMsg = response.err_msg;
                this.isNullData = true;
                this.isLoading = false;
                this.bodies = [];
                this.bodyOutside.emit(response.err_msg);
                return resolve(null);
              }
              
              let _tempHead = deepKeyInObj(response, this.resHeader) ? deepKeyInObj(response, this.resHeader) : this.headers;
              this.keepOriginalBody = deepKeyInObj(response, this.resBody) ? deepKeyInObj(response, this.resBody) : this.bodies; // keep original data response for using ( e.g : _id to href , concat firstname , lastname ,...)
              if (isExporting) {
                const _tempBody = [];
                const responseBody = response.data['rows'];
                if (!responseBody) {
                  this.nullDataMsg = response.err_msg;
                  this.isNullData = true;
                  this.isLoading = false;
                  this.bodyOutside.emit(response.err_msg);
                  return;
                }
                const bodyObj = this.wishListBody ? this.wishListBody : this.keepOriginalBody;
                const whatHead = this.wishListHeader ? this.wishListHeader : _tempHead;
                for (const tb of responseBody) {
                  let tempObj = [];
                  for (const ln of bodyObj) {
                    tempObj.push(tb[ln])
                  }
                  _tempBody.push(tempObj);
                }
                this.exportData = this.arrObjToArrArr(_tempBody);
                this.exportData.unshift(whatHead);
                if (reload) {
                  this.headers = _tempHead;
                  this.bodies = this.keepOriginalBody;
                  this.isLoading = false;
                  this.checkBodyData(this.bodies);
                  this.handlePaginate(this.bodies, response.data.count);
                  this.bodyOutside.emit(this.bodies);
                }
                return resolve('Success')
              }
              this.headers = _tempHead;
              this.bodies = this.keepOriginalBody;
              this.isLoading = false;
              this.checkBodyData(this.bodies);
              this.handlePaginate(this.bodies, response.data.count);
              this.bodyOutside.emit(this.bodies);
              resolve('Success');
            },
            err => {
              this.nullDataMsg = err;
              this.isNullData = true;
              this.isLoading = false;
              this.bodyOutside.emit(err);
            }
          );
        })

      }
      else {
        this.isLoading = false;
      }
    }
    catch (e) {
      this.nullDataMsg = e;
      this.isLoading = false;
      this.bodies = [];
    }

  }

  fetchDataByInjection() {
    if (this.showPagination == false) this.selectedLimit = 1000;
    this.isLoading = false;
    this.colSpanLoad = this.headers.length + (this.hasActionHeader ? 2 : 1);
    this.checkBodyData(this.bodies);
    this.handlePaginate(this.bodies, this.countManual);
  }

  handleFormatExcelBeforeExport(callback: (body: any) => any) {
    const tempBody = [...this.bodies];
    const formatData = callback(tempBody);
    const _tempBody = [];
    const responseBody = formatData; //response.data['rows'];
    if (!responseBody) {
      this.nullDataMsg = "No data";
      this.isNullData = true;
      this.isLoading = false;
      this.bodyOutside.emit(this.nullDataMsg);
      return;
    }
    const bodyObj = this.wishListBody;
    const whatHead = this.wishListHeader;
    for (const tb of responseBody) {
      let tempObj = [];
      for (const ln of bodyObj) {
        tempObj.push(tb[ln])
      }
      _tempBody.push(tempObj);
    }
    this.exportData = this.arrObjToArrArr(_tempBody);
    this.exportData.unshift(whatHead);
    return this.exportData;
  }

  async handleExportExcel(reload = false, formatCallback?: any) {
    let ws: XLSX.WorkSheet;
    await this.fetchDataDirectly({ args: this.argsOtherApi, isExporting: true, reload });
    if (formatCallback) this.handleFormatExcelBeforeExport(formatCallback);
    if (this.exportType === 'local') {
      ws = XLSX.utils.table_to_sheet(this.table.nativeElement);
    } else if (this.exportType === 'server') {
      ws = XLSX.utils.aoa_to_sheet(this.exportData);
    }
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, this.sheetName);
    /* save to file */
    XLSX.writeFile(wb, `${moment(moment()).valueOf().toString()}_${this.fileName}.xlsx`);
  }

  prevPage(isFirst = false) {
    if (this.currPage <= 1) return;
    isFirst ? this.currPage = 1 : this.currPage--;
    this.isLoading = true;
    this.argsOtherApi['params']['page'] = this.currPage;
    this.argsOtherApi['params']['pagesize'] = this.selectedLimit;
    this.fetchDataDirectly({ args: this.argsOtherApi })
  }

  nextPage(isLast = false) {
    if (this.currPage >= this.totalPage) return;
    isLast ? this.currPage = this.totalPage : this.currPage++;
    this.isLoading = true;
    this.argsOtherApi['params']['page'] = this.currPage;
    this.argsOtherApi['params']['pagesize'] = this.selectedLimit;
    this.fetchDataDirectly({ args: this.argsOtherApi })
  }

  onSelected(newSelected) {
    this.selectedLimit = parseInt(newSelected)
    if (this.directFetch) {
      this.currPage = 1 //Math.ceil(this.totalItem / this.selectedLimit); // cho quay lại trang đầu thay vì trang cuối
      this.argsOtherApi['params']['page'] = this.currPage;
      this.argsOtherApi['params']['pagesize'] = this.selectedLimit;
      this.fetchDataDirectly({
        args: this.argsOtherApi
      })
    } else {
      this.fetchDataByInjection()
    }
  }

  onSearch(callback?: any) {
    this.currPage = 1;
    this.fetchDataDirectly({ args: this.argsOtherApi }).then(() => {
      if (callback) callback();
      if (this.totalItem < this.selectedLimit) {
        this.currPage = 1 //this.totalItem > 0 ? Math.ceil(this.totalItem / this.selectedLimit) : 1; // cho quay lại trang đầu thay vì trang cuối
      }
    });
  }

  handlePaginate(items, count = 0) {
    this.totalPage = Math.ceil(count > 0 ? count / this.selectedLimit : items.length / this.selectedLimit)
    this.totalItem = count ? count : items.length
    this.totalItem == 0 ? this.currPage = 1 : false;
    this.fromItem = this.totalItem > 0 ? this.selectedLimit * (this.currPage - 1) + 1 : 0
    this.toItem = this.selectedLimit * this.currPage < this.totalItem ? this.selectedLimit * this.currPage : this.totalItem
  }

  validateBodyType(body) {
    return this.arrObjToArrArr(body);
  }

  checkBodyData(body) {
    if (body && body.length < 1) return this.isNullData = true;
    this.isNullData = false;
  }

  searchByDate(dateType, value) {
    switch (dateType) {
      case 'fromdate':
        this.fromdate = value;
        this.argsOtherApi['params']['fromdate'] = moment(moment(this.fromdate).format('yyyy-MM-DD[T00:00:00.000Z]')).toISOString()
        break;
      case 'todate':
        this.todate = value;
        this.argsOtherApi['params']['todate'] = moment(moment(this.todate).format('yyyy-MM-DD[T23:59:59.000Z]')).toISOString();
        break;
    }
    this.fetchDataDirectly({ args: this.argsOtherApi })
  }

  checkFieldBeSorted(idx) {
    var r = this.sortField.findIndex(i => i.pos === idx);
    if (r != -1) {
      return true
    }
  }

  sortThis(idx) {
    var r = this.sortField.findIndex(i => i.pos === idx);
    if (r != -1) {
      switch (this.sortField[r].sortType) {
        case 'asc':
          $('.head-icon-' + idx).removeClass(this.incrIcon)
          $('.head-icon-' + idx).addClass(this.decrIcon)
          this.sortField[r].sortVal = -1
          this.sortField[r].sortType = 'desc'
          break;
        case 'desc':
          $('.head-icon-' + idx).removeClass(this.decrIcon)
          $('.head-icon-' + idx).addClass(this.incrIcon)
          this.sortField[r].sortVal = 1
          this.sortField[r].sortType = 'asc'
          break;
      }
      let field = this.sortField[r].field
      let val = this.sortField[r].sortVal
      this.argsOtherApi['params']['sort'] = { [field]: val }
      this.fetchDataDirectly({ args: this.argsOtherApi })
    }
  }

  addClassByCon(idx) {
    var r = this.sortField.findIndex(i => i.pos === idx);
    if (r != -1) {
      switch (this.sortField[r].sortType) {
        case 'asc':
          return 'fa ' + this.incrIcon
        case 'desc':
          return 'fa ' + this.decrIcon
      }

    }
  }

  convertStatus(status, toText = false) {
    switch (status) {
      case 'INIT':
        return toText ? 'Khởi tạo' : decodeURI('<span class="badge badge-pill badge-primary"> Khởi tạo </span>')
      case 'DELETED':
        return toText ? 'Quá hạn xử lý' : decodeURI('<span class="badge badge-secondary"> Quá hạn xử lý </span>')
      case 'SUCCESS':
        return toText ? 'Thành công' : decodeURI('<span class="badge badge-pill badge-success"> Thành công </span>')
      case 'FAILED':
        return toText ? 'Thất bại' : decodeURI('<span class="badge badge-pill badge-danger"> Thất bại </span>')
      case 'CONFIRMED':
        return toText ? 'Đã xác nhận' : decodeURI('<span class="badge badge-pill badge-info"> Đã xác nhận </span>')
      default:
        return ''
    }
  }

  onReload(callback?: any) {
    this.fetchDataDirectly({ args: this.argsOtherApi }).then(() => {
      if (callback) callback();
    })
  }

  //#endregion MAIN FUNCTION

  //#region HELPER FUNCTION
  /**
   * This function will covert array of object key to array of array key
   * @param arr array , include object or array
   * @example [[1,2],{a:1,b:2}]
   */
  arrObjToArrArr(arr) {
    this.exceptList ? this.exceptList : [];
    return arr.map(b => {
      let isArray = b instanceof Array
      if (!isArray) {
        if (this.exceptList.length > 0) {
          return Object.keys(b).filter(el => {
            return !this.exceptList.includes(el);
          }).map(function (key) {
            return b[key]
          });
        } else {
          return Object.keys(b).map(function (key) {
            return b[key]
          });
        }
      }
      return b
    })

  }

  removeDuplicateBetweenTwoArray(arr1 = [], arr2 = []) {
    if (arr1.length == 0 && arr2.length == 0) return [];
    if (arr1.length == 0) return arr2;
    if (arr2.length == 0) return arr1;
    return arr1.length >= arr2.length ? arr1.filter(val => !arr2.includes(val)) : arr2.filter(val => !arr1.includes(val))
  }

  /**
   * This function will get all query string after subpath ( after <url>? )
   */
  $_GET() {
    var parts = window.location.search.substr(1).split("&");
    var $_GET = {};
    for (var i = 0; i < parts.length; i++) {
      var temp = parts[i].split("=");
      $_GET[decodeURIComponent(temp[0])] = decodeURIComponent(temp[1]);
    }
    return $_GET;
  }

  //#endregion HELPER FUNCTION
}

