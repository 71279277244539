import { Injectable } from '@angular/core';
import {
  Router,
  CanActivate,
  RouterStateSnapshot,
  ActivatedRouteSnapshot,
} from '@angular/router';
import { Observable } from 'rxjs/Observable';

@Injectable()
export class AuthGuard implements CanActivate {
  authService: any;
  startupService: any;

  constructor(private router: Router) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | boolean {
    if (this.authService.isLoggedIn) {
      return true;
    } else {
      if (this.startupService.startupUrl) {
        this.router.navigate(['/login'], { queryParams: { returnUrl: this.startupService.startupUrl } });
      } else {
        this.router.navigate(['/login'], { queryParams: { returnUrl: state.url } });
      }
    };
  }

  setAuthService(service) {
    this.authService = service;
  }

  setStartupService(service) {
    this.startupService = service;
  }
}
