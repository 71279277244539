import {Injectable} from "@angular/core";

declare var document: any;

@Injectable()
export class Script {

  private scripts: any = {};

  constructor() {
    let ScriptStore = [
      {name: 'googleMap', src: 'https://maps.googleapis.com/maps/api/js?key=AIzaSyAjOoDxF76MXakGXM6HGirF4QIObNaBp5g'},
      {name: 'd3js', src: 'https://d3js.org/d3.v3.min.js'},
      {name: 'moment', src: 'https://cdn.jsdelivr.net/momentjs/latest/moment.min.js'},
      {name: 'daterangepicker', src: 'https://cdn.jsdelivr.net/npm/daterangepicker/daterangepicker.min.js'}
    ]
    ScriptStore.forEach((script: any) => {
      this.scripts[script.name] = {
        loaded: false,
        src: script.src
      };
    });
  }

  load(...scripts: string[]) {
    var promises: any[] = [];
    scripts.forEach((script) => promises.push(this.loadScript(script)));
    return Promise.all(promises);
  }

  loadScript(name: string) {
    return new Promise((resolve, reject) => {
      //resolve if already loaded
      if (this.scripts[name].loaded) {
        resolve({script: name, loaded: true, status: 'Already Loaded'});
      }
      else {
        //load script
        let script = document.createElement('script');
        script.type = 'text/javascript';
        script.src = this.scripts[name].src;
        if (script.readyState) {  //IE
          script.onreadystatechange = () => {
            if (script.readyState === "loaded" || script.readyState === "complete") {
              script.onreadystatechange = null;
              this.scripts[name].loaded = true;
              resolve({script: name, loaded: true, status: 'Loaded'});
            }
          };
        } else {  //Others
          script.onload = () => {
            this.scripts[name].loaded = true;
            resolve({script: name, loaded: true, status: 'Loaded'});
          };
        }
        script.onerror = (error: any) => resolve({script: name, loaded: false, status: 'Loaded'});
        document.getElementsByTagName('head')[0].appendChild(script);
      }
    });
  }

}
