import { abstractSendAction } from './../../services';
import { NppToasterService } from './../../../npp-common/toaster/toaster.service';
import { Component, ViewEncapsulation, Input, EventEmitter, Output, OnInit } from '@angular/core';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { ApiService } from 'app/api.service';
import { SELECT_OPTION, API_DEFINE, TYPE_SELECT } from 'app/loan-referral/layout/form-type';

@Component({
  selector: 'custom-select',
  templateUrl: 'custom-select.component.html',
  styleUrls: ['custom-select.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class CustomSelect implements OnInit {
  @Input() aliasName: string;
  @Input() valueBind: any;

  @Input() labelName: string;
  @Input() showAlert: boolean = false;
  @Input() typeSelect: TYPE_SELECT = 'select';
  @Input() placeholder: string = '';

  @Input() required: boolean = false;
  @Input() disabled: boolean = false;
  @Input() listOption: SELECT_OPTION[] = [];
  @Input() apiAjax: API_DEFINE = null;
  @Input() set bindOptions(listOptBind: SELECT_OPTION[]) { this.listOption = listOptBind };
  @Output() valueBindChange = new EventEmitter();
  @Output() onChange = new EventEmitter();

  @Input() valueDatalist: any;

  constructor(public apiService: ApiService, private toaster: NppToasterService) { }

  ngOnInit() {
    if (!this.apiAjax) {
      if (this.typeSelect !== 'select' && this.listOption) {
        const foundData = this.listOption.find(v => v.val === this.valueBind)
        this.valueDatalist = foundData ? [foundData] : [];
      }
    } else {
      this.fetchData();
    }
  }

  dropdownSettings: IDropdownSettings = {
    singleSelection: true,
    idField: 'val',
    textField: 'name',
    itemsShowLimit: 3,
    allowSearchFilter: true,
    searchPlaceholderText: 'Tìm kiếm',
    noDataAvailablePlaceholderText: 'Không có dữ liệu',
    maxHeight: 197,
  }

  selectItem(e) {
    this.valueBind = e.val;
    this.valueDatalist[0] = e;
    this.valueBindChange.emit(this.valueBind);
  }

  deselectItem() {
    this.valueBind = null;
    this.valueDatalist[0] = [];
    this.valueBindChange.emit(this.valueBind);
  }

  changeItem(e) {
    if (e !== null && e !== undefined) {
      this.valueBind = e.val || e;
      const foundData = this.listOption.find(v => v.val === this.valueBind)
      this.valueDatalist = foundData ? [foundData] : [];
      this.valueBindChange.emit(this.valueBind);
    } else {
      this.valueDatalist = [];
      this.valueBindChange.emit(null);
    }
  }

  async fetchData(apiAJAX?: API_DEFINE) {
    try {
      const res = await abstractSendAction(this.apiService, this.toaster, apiAJAX ? apiAJAX : this.apiAjax);
      if (!res) {
        this.toaster.failed(`Không thể lấy dữ liệu ${apiAJAX ? apiAJAX.action : this.apiAjax.action}`);
        return;
      }
      if (res.result !== 0) {
        this.toaster.failed(res.err_msg);
        return;
      }
      this.listOption = res.data;

      const foundData = this.listOption.find(v => v.val === this.valueBind)
      this.valueDatalist = foundData ? [foundData] : [];
      return;
    }
    catch (e) {
      this.toaster.failed(e);
    }

  }
}